import { createContext, useState, useEffect } from "react";
import axios from "axios";
import { url } from "./url";
import { useNavigate } from "react-router-dom";
import io, { Socket } from "socket.io-client";
// const socket = io.connect("http://localhost:8080");
const socket = io.connect("https://api.h3budz.com/");

export const MessageContext = createContext();

export function MessageContextProvider({ children }) {
	const navigate = useNavigate();
	const [messageReceived, setMessageReceived] = useState(0);
	const [openConFirmationPopup, setOpenConfirmationPopup] = useState(false);
	const [openAlert, setOpenAlert] = useState(false);
	const [color, setColor] = useState("");
	const [message, setMessage] = useState("");
	const [messages, setMessages] = useState("");
	const [acknowledged, setAcknowledged] = useState(false);

	const handleAlert = (txt, color) => {
		setOpenAlert(true);
		setMessage(txt);
		setColor(color);
	};
	const fetchMessage = () => {
		axios
			.get(url + "/get_messages")
			.then((response) => {
				const result = response.data.received;
				const unread = result.filter((msg) => msg.isRead === 0);
				setMessageReceived(unread.length);

				setMessages(response.data);
			})
			.catch((error) => {
				console.error("Error fetching messages:", error);
			});
	};
	const submitResponse = (data) => {
		axios
			.post(url + "/reply_email", {
				send_to: data.send_to,
				send_from: data.send_from,
				message: data.message,
				orderNumber: data.orderNumber,
				name: data.name,
				currentMessageId: data.currentMessageId,
			})
			.then((response) => {
				if (response.data.message === "Email sent") {
					socket.emit(
						"reply_message",
						{ send_to: data.send_to },
						(response) => {
							console.log(response.status);
							setAcknowledged(false);
						}
					);

					setOpenConfirmationPopup(true);
					handleAlert(response.data.message, "success");
					setOpenAlert(true);
					setTimeout(() => {
						window.location.reload();
					}, 1000);
				} else {
					handleAlert("Message failed!", "error");
				}
			});
	};

	const sendMessage = (email, message, orderNumber, name) => {
		axios
			.post(url + "/contact_by_order", {
				email: email,
				message: message,
				orderNumber: orderNumber,
				name: name,
			})
			.then((response) => {
				setOpenConfirmationPopup(true);
				socket.emit("message_admin", { send_to: email }, (response) => {
					console.log(response.status);
					setAcknowledged(false);
				});
				setAcknowledged(false);
				setTimeout(() => {
					navigate("/shop");
				}, 2100);
			});
	};

	const deleteMessage = (id) => {
		axios.delete(url + `/delete_message/${id}`).then((res) => {
			if (res.data.message !== "delete faild") {
				handleAlert(res.data.message, "success");
				setOpenAlert(true);
			} else {
				handleAlert(res.data.message, "error");
			}
		});

		setTimeout(() => {
			window.location.reload();
		}, 2000);
	};

	useEffect(() => {
		fetchMessage();
	}, []);

	useEffect(() => {
		socket.on("receive_message", (data) => {
			setAcknowledged(true);
			fetchMessage();
		});

		// return () => {
		// 	socket.off("receive_message");
		// };
	}, []);

	useEffect(() => {
		socket.on("receive_reply", (data) => {
			setAcknowledged(true);
			fetchMessage();
		});

		// return () => {
		// 	socket.off("receive_reply");
		// };
	}, []);

	return (
		<MessageContext.Provider
			value={{
				messageReceived,
				messages,
				sendMessage,
				setMessageReceived,
				setMessages,
				fetchMessage,
				submitResponse,
				openConFirmationPopup,
				openAlert,
				color,
				message,
				handleAlert,
				deleteMessage,
				setOpenAlert,
			}}
		>
			{children}
		</MessageContext.Provider>
	);
}
export default MessageContextProvider;
