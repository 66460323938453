import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import AlertPopUp from "./AlertPopUp";
import { MessageContext } from "../MessageContext";
import { Paper } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

const darkTheme = createTheme({ palette: { mode: "dark" } });

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	minWidth: "80%",
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

export default function MessageReply(props) {
	const {
		submitResponse,
		openAlert,
		setOpenAlert,
		color,
		message,
		handleAlert,
		deleteMessage,
	} = useContext(MessageContext);

	const { open, data, setSelectedMessage, currentUser, activeTab } = props;
	const [confirmDelete, setConfirmDelete] = useState(false);

	const [isReply, setIsReply] = useState(false);
	const [textValue, setTextValue] = useState("");

	const handleClose = () => {
		setSelectedMessage(null);
		setConfirmDelete(false);
	};

	const handleTextareaChange = (e) => {
		setTextValue(e.target.value);
	};

	return (
		<div>
			<AlertPopUp
				open={openAlert}
				setOpen={setOpenAlert}
				message={message}
				handleClick={handleAlert}
				color={color}
			/>
			<ThemeProvider theme={darkTheme}>
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
					sx={{ width: { md: "75vw", lg: "100vw" }, margin: "auto" }}
				>
					<Paper elevation={1}>
						<Box sx={style}>
							<Typography id="modal-modal-title" variant="h6" component="h2">
								From: {data?.name}
							</Typography>
							<Typography id="modal-modal-title" variant="h6" component="h2">
								Subject:{" "}
								{"Question About # " + data?.orderNumber?.toUpperCase()}
							</Typography>
							<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
								Message: <br></br>
								{data?.message}
							</Typography>
							{isReply ? (
								<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
									<TextareaAutosize
										minRows={10}
										maxRows={20}
										placeholder="Type here"
										value={textValue}
										onChange={handleTextareaChange}
										style={{ width: "100%" }}
									/>
								</Typography>
							) : (
								<></>
							)}

							{!isReply ? (
								<Button
									variant="contained"
									style={{ marginRight: "10px" }}
									onClick={() => setIsReply(true)}
									disabled={activeTab === "sent"}
								>
									Reply
								</Button>
							) : (
								<Button
									color="success"
									variant="contained"
									style={{ marginRight: "10px" }}
									onClick={() =>
										submitResponse({
											send_to: data.send_from,
											send_from: data.send_to,
											message: textValue,
											orderNumber: data.orderNumber,
											name: currentUser.data.firstName,
											currentMessageId: data.id,
										})
									}
								>
									Send
								</Button>
							)}

							{currentUser.data.role === "admin" ? (
								<span>
									<Button
										color="warning"
										variant="contained"
										style={{ marginRight: "10px" }}
										hidden={confirmDelete}
										onClick={() => setConfirmDelete(true)}
									>
										Delete
									</Button>
									<Button
										color="error"
										variant="contained"
										style={{ marginRight: "10px" }}
										hidden={!confirmDelete}
										onClick={() => {
											deleteMessage(data.id);
										}}
									>
										CONFIRM
									</Button>
								</span>
							) : (
								<></>
							)}
						</Box>
					</Paper>
				</Modal>
			</ThemeProvider>
		</div>
	);
}
