import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import { useLogin, useSignUp } from "../service/authService";
import AlertPopUp from "./AlertPopUp";
import { Button, ButtonBase } from "@mui/material";
import Mask from "./InputMask";

export default function Login(props) {
	let [authMode, setAuthMode] = useState("signin");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [DOB, setDOB] = useState("");
	const [address, setAddress] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [zipcode, setZipcode] = useState("");
	const [referralCode, setReferralCode] = useState("");
	const [phone, setPhone] = useState("");
	const [open, setOpen] = useState(false);
	const { errorMessage } = useContext(AuthContext);

	const changeAuthMode = () => {
		setAuthMode(authMode === "signin" ? "signup" : "signin");
	};

	const { mutateAsync: signUpMutation } = useSignUp({
		onSuccess: (response) => { },
	});

	const handleClick = () => {
		setOpen(true);
	};

	const { mutateAsync: loginMutation } = useLogin({
		onSuccess: (response) => {
			handleClick();
		},
	});

	const handleDOBchange = (value) => {
		setDOB(value);
	};

	const handlePhoneChange = (value) => {
		setPhone(value);
	};

	const handleZipcodeChange = (value) => {
		setZipcode(value);
	};

	if (authMode === "signin") {
		return (
			<div className="Auth-form-container">
				<form className="Auth-form">
					<div className="Auth-form-content">
						<p className="errorMessage">{errorMessage}</p>
						<h3 className="Auth-form-title whiteText">Sign In</h3>
						<div className="text-center whiteText">
							Not registered yet?
							<Button
								variant="outlined"
								style={{
									backgroundColor: "#094100",
									border: "none",
									color: "white",
									marginLeft: "10px",
								}}
							>
								<span className=" whiteText" onClick={changeAuthMode}>
									Sign Up
								</span>
							</Button>
						</div>
						<div
							className="form-group mt-3 whiteText col-8"
							style={{ margin: "auto" }}
						>
							<label>Email address</label>
							<input
								type="email"
								className="form-control mt-1"
								placeholder="Enter email"
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						<div
							className="form-group mt-3 whiteText col-8"
							style={{ margin: "auto" }}
						>
							<label>Password</label>
							<input
								type="password"
								className="form-control mt-1"
								placeholder="Enter password"
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
						<div className="d-grid gap-2 mt-5">
							<button
								style={{
									backgroundColor: "#094100",
									border: "none",
									width: "200px",
									margin: "auto",
								}}
								onClick={(e) => {
									e.preventDefault();
									loginMutation({
										email,
										password,
									});
								}}
								className="btn btn-primary"
							>
								Login
							</button>
						</div>

						<p className="text-center mt-2 whiteText">
							Forgot<Link to='/reset-password'>password?</Link>
						</p>

					</div>
				</form>
			</div>
		);
	}

	return (
		<div
			className="Auth-form-container"
			style={{ maxWidth: "90%", width: "800px" }}
		>
			<form className="Auth-form">
				<AlertPopUp />
				<div className="Auth-form-content">
					<p className="errorMessage">{errorMessage}</p>
					<h3 className="Auth-form-title whiteText">Sign Up</h3>
					<div className="text-center whiteText">
						Already registered?{" "}
						<Button
							variant="outlined"
							style={{
								backgroundColor: "#094100",
								border: "none",
								color: "white",
								marginLeft: "10px",
							}}
						>
							<span className=" whiteText" onClick={changeAuthMode}>
								Sign In
							</span>
						</Button>
					</div>
					<div className="d-flex ">
						<div className="form-group whiteText col-md-6 p-2">
							<label>Email address</label>
							<input
								type="email"
								className="form-control mt-1"
								placeholder="Email Address"
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						<div className="form-group whiteText col-md-6 p-2">
							<label>Password</label>
							<input
								type="password"
								className="form-control mt-1 "
								placeholder="Password"
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
					</div>

					<div className="d-flex">
						<div className="form-group whiteText col-md-6 p-2">
							<label>First Name</label>
							<input
								type="text"
								className="form-control mt-1"
								placeholder="e.g Jane"
								onChange={(e) => setFirstName(e.target.value)}
							/>
						</div>
						<div className="form-group whiteText col-md-6 p-2">
							<label>Last Name</label>
							<input
								type="text"
								className="form-control mt-1"
								placeholder="e.g Doe"
								onChange={(e) => setLastName(e.target.value)}
							/>
						</div>
					</div>
					<div className="d-flex">
						<div className="form-group whiteText col-md-6 p-2">
							<label>DOB</label>
							<span className="form-control mt-1">
								<Mask
									mask="99/99/9999"
									value={DOB}
									onChange={handleDOBchange}
									placeholder="MM-DD-YYYY"
								/>
							</span>
						</div>
						<div className="form-group  whiteText col-md-6 p-2">
							<label>Phone Number</label>
							<span className="form-control mt-1">
								<Mask
									mask="(999)-999-9999"
									value={phone}
									onChange={handlePhoneChange}
									placeholder="e.g (111)111-1111"
								/>
							</span>
						</div>
					</div>
					<div className="form-group whiteText p-2">
						<label>Address</label>
						<input
							type="text"
							className="form-control mt-1"
							placeholder="e.g 123 Ocean Ave"
							onChange={(e) => setAddress(e.target.value)}
						/>
					</div>
					<div className="d-flex">
						<div className="form-group whiteText col-md-6 p-2">
							<label>City</label>
							<input
								type="text"
								className="form-control mt-1"
								placeholder="e.g Brooklyn"
								onChange={(e) => setCity(e.target.value)}
							/>
						</div>
						<div className="form-group whiteText col-md-6 p-2">
							<label>State</label>
							<input
								type="text"
								className="form-control mt-1"
								placeholder="e.g New York"
								onChange={(e) => setState(e.target.value)}
							/>
						</div>
					</div>
					<div className="d-flex">
						<div className="form-group  p-2 whiteText col-md-6">
							<label>Zip Code</label>
							<span className="form-control mt-1">
								<Mask
									mask="99999"
									value={zipcode}
									onChange={handleZipcodeChange}
									placeholder="e.g 11223"
								/>
							</span>
						</div>
						<div className="form-group p-2 whiteText col-md-6">
							<label>Referral Code</label>
							<input
								type="text"
								className="form-control m-1"
								placeholder="Referral Code"
								onChange={(e) => setReferralCode(e.target.value)}
							/>
						</div>
					</div>
					<div className="d-grid gap-2 mt-3 whiteText">
						<Button
							onClick={(e) => {
								e.preventDefault();
								signUpMutation({
									firstName,
									lastName,
									email,
									password,
									DOB,
									phone,
									address,
									city,
									state,
									referralCode,
									zipcode,
								});
							}}
							className="btn btn-primary"
							variant="contained"
							style={{
								backgroundColor: "#094100",
								border: "none",
								width: "200px",
								margin: "auto",
							}}
						>
							Submit
						</Button>
					</div>
					{
						// <p className="text-center mt-2 whiteText">
						// 	Forgot{"   "}
						// 	<a
						// 		href="#"
						// 		style={{
						// 			textDecoration: "none",
						// 			color: "#094100",
						// 			fontWeight: "500",
						// 			marginLeft: "3px",
						// 		}}
						// 	>
						// 		Password?
						// 	</a>
						// </p>
					}
				</div>
			</form>
		</div>
	);
}
