import React, { useEffect, useState } from "react";
import CustomizedAccordions from "./CustomizedAccordions";
import h3Logo from "../images/h3logo.png";
import { Button, Paper, Typography, TextareaAutosize } from "@mui/material";
import { url } from "../url";
import axios from "axios";
import AlertPopUp from "./AlertPopUp";
import ContactByOrder from "./ContactByOrder";
import Chart from "./PieChart";

export default function HistoryDetail(props) {
	const { data, getOrderHistory, currentUser } = props;
	const [show, setShow] = useState(false);

	const showMessage = () => setShow(true);
	const hideMessage = () => setShow(false);
	const [openComment, setOpenComment] = useState(false);
	const [textValue, setTextValue] = useState("");

	// const [allPayments, setAllPayments] = useState([]);
	// const [paymentLink, setPaymentLink] = useState("");

	const [open, setOpen] = useState(false);
	const [color, setColor] = useState("");
	const [message, setMessage] = useState("");
	const [comments, setComments] = useState([]);
	const handleClick = (txt, color) => {
		setOpen(true);
		setMessage(txt);
		setColor(color);
	};

	// const sendPaymentLink = async (order) => {
	// 	if (paymentLink.length) {
	// 		try {
	// 			await axios.post(url + "/send_payment", {
	// 				link: paymentLink,
	// 				order: order,
	// 			});
	// 			handleClick("Payment Link Sent, Reloading...");
	// 			setTimeout(() => {
	// 				window.location.reload();
	// 			}, 3000);
	// 		} catch (error) {
	// 			handleClick("Faild to send payment link..", "error");
	// 		}
	// 	} else {
	// 		handleClick("Input Required", "error");
	// 	}
	// };

	const cancelIncompleteOrder = async (orderNumber) => {
		await axios
			.delete(url + "/cancel_incomplete_order", {
				data: { orderNumber: orderNumber },
			})
			.then((response) => {
				if (currentUser.data.userId !== 3) {
					console.log(currentUser.data.userId);
					handleClick(response.data.message, "error");
				} else if (response.affectedRows === 0) {
					console.log(response.affectedRows);
					handleClick("deletion failed....", "error");
				} else {
					handleClick(
						"Order Cancelled, redirect back to shop in 2 second!",
						"success"
					);
				}
				setTimeout(() => {
					getOrderHistory();
				}, 2000);
			});
	};

	const confirmPaymentComplete = (userId, orderNumber) => {
		axios.post(url + "/confirm_payment_complete", {
			userId: parseInt(userId),
			orderNumber: orderNumber,
		});
		handleClick("Transaction Completed");
		setTimeout(() => {
			getOrderHistory();
		}, 2000);
	};

	const addComment = (orderNumber, textValue, sender_id, customer_id) => {
		axios.post(url + `/add_comment`, {
			orderNumber: orderNumber,
			comment: textValue,
			sender_id: sender_id,
			customer_id: customer_id,
		});
		handleClick("Comment created.");
		setTimeout(() => {
			window.location.reload();
		}, 2000);
	};

	const getComments = () => {
		axios.get(url + "/get_comments").then((result) => {
			setComments(result.data);
		});
	};
	// const getPaymentLink = async () => {
	// 	axios.get(url + "/payment_link").then((response) => {
	// 		setAllPayments(response.data);
	// 	});
	// };

	useEffect(() => {
		// getPaymentLink();
		getComments();
	}, []);

	return (
		<div style={{ marginBottom: "50px", color: "white" }}>
			<ContactByOrder
				show={show}
				hideMessage={hideMessage}
				orderNumber={data.orderNumber}
				email={data.email}
				name={data.firstName}
				userId={currentUser.data.userId}
			/>
			<AlertPopUp
				open={open}
				setOpen={setOpen}
				message={message}
				handleClick={handleClick}
				color={color}
			/>
			<table className="orderHistory">
				<tbody>
					<tr>
						<th className="orderHistoryImage"></th>
						<th>Product</th>
						<th>Option</th>
						<th>QT</th>
						<th>Price</th>
						<th>Status</th>
					</tr>

					{data.items?.map((item, index) => (
						<tr key={index}>
							<td className="orderHistoryImage">
								<img
									alt={item.url}
									src={item.url === null ? h3Logo : item.url}
									style={{ width: "auto", height: "100px" }}
								/>
							</td>
							<td>
								{item.title === null
									? "CODE: " + item.note.toUpperCase()
									: item.title.toUpperCase()}
							</td>
							<td>
								{item.option
									? item.option.toUpperCase()
									: "CODE: " + item.note.toUpperCase()}
							</td>
							<td> {item.qt}</td>
							<td>${item.price}</td>
							<td>{item.status?.toUpperCase()}</td>
						</tr>
					))}
				</tbody>
			</table>

			<Paper
				sx={{ padding: "20px", backgroundColor: "rgba(23, 20, 20, 0.8)" }}
				elevation={4}
			>
				<div className="historyDetailInfoBox">
					<div className="historyDetailShippingInfo">
						<p style={{ fontWeight: "500", opacity: "1" }}>
							Shipping Information
						</p>
						<p style={{ margin: "0", textTransform: "capitalize" }}>
							{data.firstName + "  " + data.lastName}
						</p>
						<p style={{ margin: "0", textTransform: "capitalize" }}>
							{data.shipping_address}
						</p>
						<p style={{ margin: "0", textTransform: "capitalize" }}>
							{data.shipping_city}
						</p>
						<p style={{ margin: "0", textTransform: "capitalize" }}>
							{data.shipping_state}
						</p>
						<p style={{ margin: "0", textTransform: "capitalize" }}>
							{data.shipping_zipcode}
						</p>
						<p style={{ margin: "0", textTransform: "capitalize" }}>
							{data.shipping_phone}
						</p>
						<p
							style={{
								margin: "0",
								textTransform: "capitalize",
								color: "orange",
							}}
						>
							{data.items[0].instruction}
						</p>
						<p style={{ margin: "0" }}>{data.email.toLowerCase()}</p>
						{currentUser.data.role !== "admin" && (
							<div style={{ marginTop: "15px" }}>
								<Button variant="contained" onClick={showMessage}>
									Message
								</Button>
							</div>
						)}
					</div>
					<div className="historyDetailPricingBox">
						{
							// <div className="d-flex justify-content-between m-2">
							// 	<span>Method:</span>
							// 	<span
							// 		style={{
							// 			color: "orange",
							// 			fontWeight: "500",
							// 			textAlign: "center",
							// 		}}
							// 	>
							// 		{data.items[0].shipping_method}
							// 	</span>
							// </div>
						}
						<div className="d-flex justify-content-between m-2">
							<div>Next Day Delivery ( Free Gift ):</div>
							<div>{data.items[0].free_before_12 === 1 ? "YES" : "NO"}</div>
						</div>
						<div className="d-flex justify-content-between m-2">
							<div>Method:</div>
							<div>{data.items[0].paymentMethod}</div>
						</div>
						{data.items[0].discount_amount !== "0.00" ? (
							<div>
								<div className="d-flex justify-content-between m-2">
									<div>Subtotal:</div>
									<div
										style={{
											textDecoration:
												data.items[0].discount_amount !== "0.00"
													? "line-through"
													: "none",
										}}
									>
										{" $ " +
											(
												parseFloat(data.items[0].subtotal) +
												parseFloat(data.items[0].discount_amount)
											).toFixed(2)}
									</div>
								</div>
								<div className="d-flex justify-content-between m-2">
									<div></div>
									<div>{" $ " + data.items[0].subtotal}</div>
								</div>
							</div>
						) : (
							<div className="d-flex justify-content-between m-2">
								<div>Subtotal:</div>
								<div>{" $ " + data.items[0].subtotal}</div>
							</div>
						)}
						<div className="d-flex justify-content-between m-2">
							<span>Delivery:</span>
							<span>
								{data.items[0].delivery_fee === 0.0
									? "FREE"
									: " $ " + data.items[0].delivery_fee}
							</span>
						</div>
						<div className="d-flex justify-content-between m-2">
							<span>Tip Amount:</span>
							<span>{" $ " + data.items[0].tip_amount}</span>
						</div>
						<div className="d-flex justify-content-between m-2">
							<span>Sales Tax:</span>
							<span>{" $ " + data.items[0].sales_tax}</span>
						</div>
						{/* <div className="d-flex justify-content-between m-2">
							<span>Discount:</span>
							<span>{" - $ " + data.items[0].discount_amount}</span>
						</div> */}
						<div className="d-flex justify-content-between m-2">
							<span>Total: </span>
							<span>{" $ " + data.items[0].total} </span>
						</div>
					</div>

					{currentUser.data.userId !== 3 && data.status !== "completed" ? (
						<></>
					) : (
						<Button
							variant="contained"
							disabled={data.status === "completed"}
							style={{
								color: "white",
								backgroundColor: data.status === "completed" ? "gray" : "red",
							}}
							onClick={() => {
								cancelIncompleteOrder(data.orderNumber);
							}}
						>
							Cancel
						</Button>
					)}
				</div>
				{currentUser.data.role === "admin" && currentUser.data.userId ? (
					<div>
						{
							// 	<div style={{ width: "100%" }}>
							// 	{
							// 		<CustomizedAccordions
							// 			data={allPayments.filter(
							// 				(item) => item.orderNumber === data.items[0].orderNumber,
							// 			)}
							// 		/>
							// 	}
							// </div>
							// <div style={{ width: "100%" }}>
							// 	<p
							// 		style={{
							// 			color: "red",
							// 			fontSize: "10px",
							// 			fontWeight: "500",
							// 		}}
							// 	>
							// 		Make sure link starts with https:// otherwise user won't be able
							// 		to open the link from email
							// 	</p>
							// 	<input
							// 		type="text"
							// 		placeholder="https:// ....."
							// 		style={{ width: "75%" }}
							// 		onChange={(e) => setPaymentLink(e.target.value)}
							// 	></input>
							// </div>
						}
						{data.status === "x" ? (
							<div style={{ marginTop: "5px" }}>
								<Button
									variant="contained"
									color="error"
									onClick={() => alert("under construction")}
								>
									Undo
								</Button>
							</div>
						) : (
							<div
								style={{ marginTop: "5px" }}
								className="d-flex justify-content-evenly"
							>
								<Button
									variant="contained"
									color="success"
									style={{ margin: "10px 15px" }}
									disabled={data.status === "completed"}
									onClick={() =>
										confirmPaymentComplete(data.user_id, data.orderNumber)
									}
								>
									Complete
								</Button>
							</div>
						)}
					</div>
				) : (
					<div></div>
				)}
			</Paper>
			<Paper
				sx={{
					display: "flex",
					justifyContent: "space-between",
					padding: "20px",
					backgroundColor: "rgba(23, 20, 20, 0.8)",
					marginTop: "15px",
					color: "white",
					fontWeight: "bold",
					flexDirection: "column",
					textAlign: "left",
				}}
				elevation={4}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<div>Comments</div>
					<Button
						variant="contained"
						color="success"
						style={{ margin: "10px 15px" }}
						onClick={() => setOpenComment(true)}
						disabled={openComment}
					>
						New
					</Button>
				</div>
				<div>
					{Array.isArray(comments) ? (
						<CustomizedAccordions
							data={comments?.filter(
								(item) => item.orderNumber === data.items[0].orderNumber
							)}
						/>
					) : null}
				</div>
				{openComment ? (
					<div>
						<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
							<TextareaAutosize
								minRows={5}
								maxRows={8}
								placeholder="Type here"
								onChange={(e) => setTextValue(e.target.value)}
								style={{ width: "100%", borderRadius: "5px" }}
							/>
						</Typography>
						<span style={{ float: "right" }}>
							{console.log(data.id)}
							<Button
								variant="contained"
								color="success"
								onClick={() =>
									addComment(
										data.orderNumber,
										textValue,
										currentUser.data.userId,
										data.user_id
									)
								}
							>
								Add
							</Button>
							<Button
								variant="contained"
								color="error"
								sx={{ marginLeft: "15px" }}
								onClick={() => setOpenComment(false)}
							>
								Cancel
							</Button>
						</span>
					</div>
				) : (
					<></>
				)}
			</Paper>
		</div>
	);
}
