import React, { useEffect, useState } from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import { url } from "../url";
import moment from "moment";

import {
	Button,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from "@mui/material";
import AlertPopUp from "./AlertPopUp";
import Modal from "react-bootstrap/Modal";
import FormSelect from "./FormSelect";

export default function CreatePromoCode() {
	const [promocodes, setPromocodes] = useState([]);
	const [code, setCode] = useState("");
	const [description, setDescription] = useState("");
	const [open, setOpen] = useState(false);
	const [color, setColor] = useState("");
	const [message, setMessage] = useState("");
	const [products, setProducts] = useState();
	const [selectedProduct, setSelectedProduct] = useState(0);
	const [selectedReferralProduct, setSelectedReferralProduct] = useState(0);
	const [currentReferralItem, setCurrentReferralItem] = useState("");

	const [updateProductId, setUpdateProductId] = useState(0);
	const [show, setShow] = useState(false);
	const [showReferral, setShowReferral] = useState(false);

	const [type, setType] = useState("");
	const [discountValue, setDiscountValue] = useState(0);

	const promoType = ["Gift Item", "Discount On Order", "Discount On Item"];

	const handleClose = () => setShow(false);
	const handleShow = (id) => {
		setUpdateProductId(id);
		setShow(true);
	};

	const formattedDate = (date) => moment.utc(date).format("YYYY-MM-DD");
	const createPromo = async () => {
		if (code.length === 0 || description.length === 0) {
			return handleClick("fields are required", "error");
		}
		if (discountValue >= 1) {
			return handleClick("Discount value must less than 1", "error");
		}
		await axios
			.post(url + "/create_promocode", {
				code,
				description,
				selectedProduct,
				discountValue,
			})
			.then((response) => {
				if (response.data.message !== "Promo code already exists") {
					handleClick(response.data.message, "success");
				} else {
					handleClick(response.data.message, "error");
				}
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			});
	};

	const saveReferralGift = () => {
		if (selectedReferralProduct) {
			axios
				.post(url + "/create_referral_item", { id: selectedReferralProduct })
				.then((response) => {
					if (response.data.message === "success") {
						handleClick("Referral Gift created", "success");
						setSelectedReferralProduct("");
						setShowReferral(false);
					}
				});
		} else {
			handleClick("no referral product selected", "error");
		}
	};

	const hideReferralModal = () => {
		setShowReferral(false);
		setSelectedReferralProduct("");
	};
	const handleClick = (txt, color) => {
		setOpen(true);
		setMessage(txt);
		setColor(color);
	};

	const deleteCode = async (id) => {
		await axios
			.delete(url + "/delete_promocode", { data: { id } })
			.then((response) => {
				if (response.data.message !== "error, pls contact admin") {
					handleClick(response.data.message, "success");
				} else {
					handleClick(response.data.message, "error");
				}
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			});
	};

	const updateCode = async (productId, id, description) => {
		await axios
			.put(url + "/update_promocode", {
				id: id,
				productId: productId,
				description: description,
			})
			.then((response) => {
				console.log(response.data.message);
				handleClick(response.data.message);
			});
	};

	const getPromocodes = async () => {
		await axios.get(url + "/all_promocodes").then((response) => {
			setPromocodes(response.data);
		});
	};

	const getProducts = async () => {
		axios.get(url + "/all_products").then((response) => {
			setProducts(response.data);
		});
	};

	const loadCurrentReferralItem = async () => {
		axios.get(url + "/load_current_referral_item").then((response) => {
			setCurrentReferralItem(response.data[0]);
		});
	};

	useEffect(() => {
		getProducts();
		getPromocodes();
		loadCurrentReferralItem();
	}, []);
	return (
		<div
			className="createPromo whiteText container"
			style={{ paddingBottom: "60px" }}
		>
			<AlertPopUp
				open={open}
				setOpen={setOpen}
				message={message}
				handleClick={handleClick}
				color={color}
			/>
			<TableContainer
				component={Paper}
				sx={{
					background: "rgba(23, 20, 20, 0.85)",
					color: "#fff",
					p: 3,
					mb: 2,
				}}
			>
				<Table
					key={code.promoCode}
					sx={{ minWidth: 650 }}
					aria-label="simple table"
				>
					<TableHead>
						<TableRow
							style={{
								color: "white",
							}}
						>
							<TableCell sx={{ color: "#fff" }}>Code</TableCell>
							<TableCell sx={{ color: "#fff" }}>Item</TableCell>
							<TableCell sx={{ color: "#fff", width: "300px" }}>
								Description
							</TableCell>
							<TableCell sx={{ color: "#fff", width: "120px" }}>Date</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{promocodes.length ? (
							promocodes?.map((code) => {
								return (
									<TableRow key={code.promoCode}>
										<TableCell sx={{ color: "white" }}>
											{code.promoCode}
										</TableCell>
										<TableCell sx={{ color: "white" }}>
											{code.product_title}
										</TableCell>
										<TableCell
											sx={{ color: "white" }}
											style={{ textOverflow: "ellipsis" }}
										>
											{code.description}
										</TableCell>
										<TableCell sx={{ color: "white" }}>
											{formattedDate(code.updated)}
										</TableCell>
										<TableCell>
											<button
												style={{
													border: "none",
													backgroundColor: "red",
													color: "white",
													width: "25px",
													height: "25px",
													margin: "5px 5px",
													borderRadius: "5px",
												}}
												onClick={() => {
													deleteCode(code.id);
												}}
											>
												<p style={{ textAlign: "center", margin: "0" }}>X</p>
											</button>
										</TableCell>
									</TableRow>
								);
							})
						) : (
							<TableRow>
								<td>No Code Found.</td>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<Modal show={show} onHide={() => setShow(false)} centered>
				<Paper>
					<Form style={{ margin: "15px" }}>
						<Form.Group className="mb-3" controlId="promocode">
							<Form.Label>Promo Code</Form.Label>
							<Form.Control
								type="text"
								placeholder="promocode"
								onChange={(e) => setCode(e.target.value)}
							/>
						</Form.Group>

						<Form.Group className="mb-3" controlId="promocode">
							<Form.Label>Select Promo type</Form.Label>
							<Form.Select
								aria-label="Default select example"
								defaultValue="option"
								onChange={(e) => setType(e.target.value)}
							>
								{promoType?.map((type, index) => {
									return (
										<option value={type} key={index}>
											{type}
										</option>
									);
								})}
							</Form.Select>
						</Form.Group>

						{type === "Gift Item" || type.length === 0 ? (
							<Form.Group className="mb-3" controlId="promocode">
								<Form.Label>
									Gift Item (
									<span style={{ color: "red" }}>
										Only use it if code meant to gift item!
									</span>
									)
								</Form.Label>
								<Form.Select
									aria-label="Default select example"
									defaultValue="option"
									onChange={(e) => setSelectedProduct(e.target.value)}
								>
									<option defaultValue="select a product">--</option>
									{products?.map((product) => {
										return (
											<option value={product.id} key={product.id}>
												{product.title}
											</option>
										);
									})}
								</Form.Select>
							</Form.Group>
						) : (
							<></>
						)}

						{type === "Discount On Order" ? (
							<Form.Group className="mb-3" controlId="promocode">
								<Form.Label>Discount value (0.9 = 10% off)</Form.Label>
								<Form.Control
									type="number"
									placeholder="Discount Value"
									step={0.01}
									onChange={(e) => setDiscountValue(Number(e.target.value))}
								/>
							</Form.Group>
						) : (
							<></>
						)}

						{type === "Discount On Item" ? (
							<Form.Group className="mb-3" controlId="promocode">
								<Form.Label>Discounted Item</Form.Label>
								<Form.Select
									aria-label="Default select example"
									defaultValue="option"
									onChange={(e) => setSelectedProduct(e.target.value)}
								>
									<option defaultValue="select a product">--</option>
									{products?.map((product) => {
										return (
											<option value={product.id} key={product.id}>
												{product.title}
											</option>
										);
									})}
								</Form.Select>
								<Form.Label>Discount value (0.9 = 10% off)</Form.Label>
								<Form.Control
									type="number"
									placeholder="Discount Value"
									step={0.01}
									onChange={(e) => setDiscountValue(Number(e.target.value))}
								/>
							</Form.Group>
						) : (
							<></>
						)}

						<Form.Group
							className="mb-3"
							controlId="description"
							onChange={(e) => setDescription(e.target.value)}
						>
							<Form.Label>Description</Form.Label>
							<Form.Control as="textarea" rows={3} />
						</Form.Group>
						<Button variant="contained" onClick={createPromo}>
							Save
						</Button>
					</Form>
				</Paper>
			</Modal>
			<Modal show={showReferral} onHide={hideReferralModal} centered>
				<Paper>
					<Form style={{ margin: "15px" }}>
						<Form.Group className="mb-3" controlId="promocode">
							<Form.Label>
								Current Item: {currentReferralItem?.title}
							</Form.Label>
						</Form.Group>
						<Form.Group className="mb-3" controlId="promocode">
							<Form.Label>
								Referral Gift (
								<span style={{ color: "red" }}>
									every eligible user would be set to this gift
								</span>
								)
							</Form.Label>
							<Form.Select
								aria-label="Default select example"
								defaultValue="option"
								onChange={(e) => setSelectedReferralProduct(e.target.value)}
							>
								<option defaultValue="select a product">--</option>
								{products?.map((product) => {
									return (
										<option value={product.id} key={product.id}>
											{product.title}
										</option>
									);
								})}
							</Form.Select>
						</Form.Group>
						<Button variant="contained" onClick={saveReferralGift}>
							Save
						</Button>
					</Form>
				</Paper>
			</Modal>
			<Button variant="contained" onClick={() => setShow(true)} sx={{ m: 2 }}>
				Create Code
			</Button>
			<Button
				variant="contained"
				onClick={() => setShowReferral(true)}
				sx={{ m: 2 }}
			>
				Set Referral Item
			</Button>
		</div>
	);
}
