import React, { useState, useContext, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import { url } from "../url";
import ConfirmationPopup from "./ConfirmationPopup";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../controller/LanguageContext";

export default function Contact() {
	const navigate = useNavigate();

	const { isEn } = useContext(LanguageContext);

	const [emailData, setEmailData] = useState({
		name: "",
		email: "",
		message: "",
	});

	const [openConFirmationPopup, setOpenConfirmationPopup] = useState(false);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setEmailData({ ...emailData, [name]: value });
	};

	const sendMessage = async () => {
		await axios.post(url + "/contact", { emailData }).then((response) => {
			setOpenConfirmationPopup(true);
			setTimeout(() => {
				navigate("/shop");
			}, 2100);
		});
	};

	return (
		<div className="fadeIn contact-container">
			<ConfirmationPopup
				openConFirmationPopup={openConFirmationPopup}
				setOpenConfirmationPopup={setOpenConfirmationPopup}
			/>
			<div className="contact-section">
				{!isEn ? (
					<>
						<h1 style={{ textAlign: "left" }}>CONTACT US</h1>
						<p style={{ textAlign: "left" }}>
							Fill in the form below and our team will get back to you shortly.
						</p>
					</>
				) : (
					<>
						<h1 style={{ textAlign: "left" }}>聯係我們</h1>
						<p style={{ textAlign: "left" }}>
							請填寫下方表格，我們的團隊將盡快與您聯繫。
						</p>
					</>
				)}

				<Form style={{ width: "100%" }}>
					<Form.Group
						className="mb-3 mt-5"
						controlId="exampleForm.ControlInput1"
					>
						<Form.Label style={{ float: "left" }}>Name</Form.Label>

						<Form.Control
							type="text"
							name="name"
							placeholder="name"
							value={emailData.name}
							onChange={handleInputChange}
							required
							style={{
								background: "black",
								color: "white",
								borderRadius: "0",
							}}
						/>
					</Form.Group>

					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label style={{ float: "left" }}>Email Address</Form.Label>
						<Form.Control
							required
							type="email"
							placeholder="name@example.com"
							name="email"
							value={emailData.email}
							onChange={handleInputChange}
							style={{
								background: "black",
								color: "white",
								borderRadius: "0",
							}}
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label style={{ float: "left" }}>Message</Form.Label>
						<Form.Control
							as="textarea"
							rows={5}
							name="message"
							placeholder="message"
							value={emailData.message}
							onChange={handleInputChange}
							style={{
								background: "black",
								color: "white",
								borderRadius: "0",
							}}
						/>
					</Form.Group>
				</Form>
			</div>

			<div style={{ margin: '10px' }}>
				<Button
					style={{
						backgroundColor:
							emailData.name.length === 0 ||
								emailData.email.length === 0 ||
								emailData.message.length === 0
								? "gray"
								: "#094100",
						border: "none",
						width: "200px",
						margin: "auto",
						borderRadius: '0'
					}}
					className="btn btn-primary buttons"
					onClick={sendMessage}
					disabled={
						emailData.name.length === 0 ||
						emailData.email.length === 0 ||
						emailData.message.length === 0
					}
				>
					{emailData.name.length === 0 ||
						emailData.email.length === 0 ||
						emailData.message.length === 0 ? (
						<>Data Required</>
					) : (
						<>Submit</>
					)}
				</Button>
			</div>

		</div>
	);
}
