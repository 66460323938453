import React, { useState } from "react";
import InputMask from "react-input-mask";

export default function Mask(props) {
	const { mask, value, onChange, placeHolder } = props;
	const handleInputChange = (e) => {
		onChange(e.target.value);
	};
	// example format
	// <InputMask
	//     mask='9999'
	//     value={props.value}
	//     onChange={props.onChange}
	//     placeholder='Enter PIN'>
	//  </InputMask>
	return (
		<InputMask
			style={{ width: "100%", border: "none" }}
			mask={mask}
			value={value}
			onChange={handleInputChange}
			placeholder={placeHolder}
		/>
	);
}
