import { createContext, useState, useEffect } from "react";
import axios from "axios";
import { url } from "./url";
export const DashContext = createContext();

export function DashContextProvider({ children }) {
	const [income, setIncome] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [groupName, setGroupName] = useState("");
	const [userGroups, setUserGroups] = useState([]);
	const [userIds, setUserIds] = useState([]);
	const [selectedEmails, setSelectedEmails] = useState([]);
	const [selectedGroup, setSelectGroup] = useState([]);
	const [showAvailableGroup, setShowAvailableGroup] = useState(false);

	const [open, setOpen] = useState(false);
	const [color, setColor] = useState("");
	const [message, setMessage] = useState("");

	const hideAvailableGroup = () => setShowAvailableGroup(false);

	const handleClick = (txt, color) => {
		setOpen(true);
		setMessage(txt);
		setColor(color);
	};

	const getIncome = async () => {
		setIsLoading(true);
		try {
			const result = await axios.get(url + "/get_income");
			setIncome(result.data);
		} catch (error) {
			console.error("Error fetching product data:", error);
			return undefined;
		}
		setIsLoading(false);
	};

	const getUserBoughtIn30Days = async () => {
		setIsLoading(true);
		try {
			const result = await axios.get(url + "/user_bought_in_30days");
			setIncome(result.data);
		} catch (error) {
			console.error("Error fetching product data:", error);
			return undefined;
		}
		setIsLoading(false);
	};

	const createUserGroup = async () => {
		setIsLoading(true);
		try {
			const result = await axios.post(url + "/create_user_group", {
				name: groupName,
			});
			getUserGroups();
		} catch (error) {
			console.log("error createing userGroup");
			return undefined;
		}
		setIsLoading(false);
	};

	const getUserGroups = async () => {
		setIsLoading(true);
		try {
			const result = await axios.get(url + `/get_user_groups`);
			setUserGroups(result.data);
		} catch (error) {
			console.log(error);
		}
		setIsLoading(false);
	};

	const deleteGroup = async (id) => {
		const result = await axios.delete(url + `/delete_group/${id}`);
		if (result.data.message === "deleted") {
			getUserGroups();
		}
	};

	const addUsersToGroup = async () => {
		setIsLoading(true);
		console.log(selectedGroup);
		const verifyGroup = selectedGroup.length ? selectedGroup : "0";
		try {
			const result = await axios.put(url + "/group_users", {
				emailObj: selectedEmails,
				selectedGroup: verifyGroup,
			});
			if (result.data.isSuccess) {
				setSelectGroup([]);
				handleClick(result.data.message, "success");
			} else {
				handleClick(result.data.message, "error");
			}
		} catch (error) {
			console.log(error);
		}
		setShowAvailableGroup(false);

		setIsLoading(false);
		getIncome();
	};

	return (
		<DashContext.Provider
			value={{
				getIncome,
				income,
				isLoading,
				getUserBoughtIn30Days,
				createUserGroup,
				setGroupName,
				getUserGroups,
				userGroups,
				deleteGroup,
				addUsersToGroup,
				userIds,
				setUserIds,
				selectedEmails,
				setSelectedEmails,
				selectedGroup,
				setSelectGroup,
				open,
				color,
				message,
				handleClick,
				setOpen,
				showAvailableGroup,
				setShowAvailableGroup,
				hideAvailableGroup,
			}}
		>
			{children}
		</DashContext.Provider>
	);
}
export default DashContextProvider;
