import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import PieChart from "./PieChart";

export default function DashAccordion(props) {
	const { datas, name, itemName, textSize, height } = props;

	return (
		<Accordion
			elevation={4}
			sx={{
				color: "white",
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				{name}
			</AccordionSummary>

			<AccordionDetails sx={{ p: 0 }}>
				<PieChart
					datas={datas}
					itemName={itemName}
					textSize={textSize}
					height={height}
				/>
			</AccordionDetails>
		</Accordion>
	);
}
