import {
	Paper,
	Box,
	Grid,
	Button,
	Modal,
	TextField,
	Typography,
	Divider,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { DashContext } from "../DashContext";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "./Table";
import DashAccordions from "./DashAccordions";
import Dropdown from "react-bootstrap/Dropdown";
import { Form } from "react-bootstrap";
import LoadingBar from "./LoadingBar";
import no_entry from "../images/no_entry.png";
import { useNavigate } from "react-router-dom";

// import SendSms from "./SendSms";

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	textAlign: "center",
	color: theme.palette.text.secondary,
	// height: 60,
	lineHeight: "60px",
}));

const darkTheme = createTheme({ palette: { mode: "dark" } });
// const lightTheme = createTheme({ palette: { mode: 'light' } });

export default function Dashboard(props) {
	const {
		getIncome,
		income,
		isLoading,
		createUserGroup,
		setGroupName,
		userGroups,
		deleteGroup,
		getUserGroups,
	} = useContext(DashContext);
	const { currentUser } = props;
	const [countdown, setCountdown] = useState(3); // Countdown starts at 3 seconds
	const navigate = useNavigate();
	const [dataType, setDataType] = useState("default");
	const [data, setData] = useState([]);
	const [show, setShow] = useState(false);

	const hideModal = () => setShow(false);
	const loadIncome = () => {
		getIncome();
		getUserGroups();
		setData(income);
	};

	useEffect(() => {
		loadIncome();
	}, []);

	useEffect(() => {
		if (currentUser.data.role !== "admin") {
			// Start countdown interval
			const interval = setInterval(() => {
				setCountdown((prev) => prev - 1);
			}, 1000);

			// Clear interval and navigate when countdown reaches zero
			if (countdown === 0) {
				clearInterval(interval);
				navigate("/");
			}

			// Cleanup interval on component unmount
			return () => clearInterval(interval);
		}
	}, [countdown, navigate]);

	if (currentUser.data.role !== "admin") {
		return (
			<div style={{ margin: "auto" }}>
				<p style={{ color: "white", fontWeight: "bold" }}>
					Redirecting in <span style={{ color: "orange" }}>{countdown}</span>
					seconds...
				</p>
				<img src={no_entry} alt="No Entry" />
			</div>
		);
	}
	if (income.length === 0) {
		return <LoadingBar />;
	}

	return (
		<div className="container mt-5" style={{ width: "100%" }}>
			<Grid
				container
				spacing={2}
				className="d-flex"
				style={{ justifyContent: "center" }}
			>
				{/* <SendSms /> */}
				<Grid item xs={12}>
					<ThemeProvider theme={darkTheme}>
						<Box
							sx={{
								p: 2,
								borderRadius: 2,
								bgcolor: "background.default",
							}}
						>
							<Typography
								variant="h5"
								align="left"
								sx={{
									color: "white",
									fontWeight: "bold",
									my: "auto",
								}}
							>
								SUMMARY
							</Typography>
							<Box
								sx={{
									borderRadius: 2,
									bgcolor: "background.default",
									display: "grid",
									gridTemplateColumns: { md: "1fr 1fr" },
									gap: 2,
									mt: 2,
								}}
							>
								<Item elevation={3} sx={{ p: 2 }}>
									<h3>TOTAL SALE</h3>
									{!isLoading ? (
										<h1>${income.income?.total_amount}</h1>
									) : (
										<CircularProgress color="success" />
									)}
								</Item>
								<Item elevation={3} sx={{ p: 2 }}>
									<h3>ITEM SOLD(GIFT INCLUDED)</h3>
									{!isLoading ? (
										<h1>{income.income?.total_qt}</h1>
									) : (
										<CircularProgress color="success" />
									)}
								</Item>
								<Item elevation={3} sx={{ p: 2 }}>
									<h3>COMPLETED ORDERS</h3>
									{!isLoading ? (
										<h1>{income.income?.total_orders}</h1>
									) : (
										<CircularProgress color="success" />
									)}
								</Item>
								<Item elevation={3} sx={{ p: 2 }}>
									<h3>BEST SELLING PRODUCT</h3>
									{!isLoading ? (
										<h3>
											{income.bestSellingItem?.title} x{" "}
											{income?.bestSellingItem?.total_quantity_sold}
										</h3>
									) : (
										<CircularProgress color="success" />
									)}
								</Item>
							</Box>
						</Box>
						<Box
							// className="hideMobile"
							sx={{
								p: 2,
								borderRadius: 2,
								bgcolor: "background.default",
								display: "grid",
								gap: 2,
								mt: 2,
							}}
						>
							<Typography
								variant="h5"
								align="left"
								sx={{ color: "white", fontWeight: "bold" }}
							>
								DATA CHART
							</Typography>
							<DashAccordions
								datas={income.eachSoldItemCount}
								name={`Inventory Statistics (${income?.eachSoldItemCount?.length})`}
								itemName="Sold"
								textSize="10"
								height={window.innerWidth > 600 ? 200 : 500}
							/>
							<DashAccordions
								datas={income.orderCountByMonth}
								name={`Orders Statistics (${income?.orderCountByMonth?.length})`}
								itemName="Order Sold"
								textSize="14"
								height="200"
							/>
							<DashAccordions
								datas={income.monthlyRev}
								name={`Revenue Statistics (${income?.monthlyRev?.length} items)`}
								itemName="Revenue"
								textSize="14"
								height="200"
							/>
							<DashAccordions
								datas={income.monthlySalesTax}
								name={`Tax Statistics (${income?.monthlySalesTax?.length} items)`}
								itemName="Tax"
								textSize="14"
								height="200"
							/>
							<DashAccordions
								datas={income.redeemedCount}
								name={`Redeem Product Statistics (${income?.redeemedCount?.length} items)`}
								itemName="Redeemed"
								textSize="14"
								height="200"
							/>
							<DashAccordions
								datas={income.orderWithCode}
								name={`Order With Promo Code (${income?.orderWithCode?.length})`}
								itemName="Codes Used With Order"
								textSize="14"
							/>
							{/* <PieChart datas={income} /> */}
						</Box>
						<Box
							sx={{
								p: 2,
								borderRadius: 2,
								bgcolor: "background.default",
								// display: "grid",
								gap: 2,
								mt: 2,
							}}
						>
							<Box
								sx={{
									display: { sm: "block", md: "flex" },
									justifyContent: "space-between",
								}}
							>
								<Typography
									variant="h5"
									align="left"
									sx={{
										color: "white",
										fontWeight: "bold",
										my: "auto",
									}}
								>
									USER DATA
								</Typography>
								<Box
									sx={{
										display: "flex",
										my: 2,
										justifyContent: "space-between",
									}}
								>
									<Button
										onClick={() => setShow(true)}
										variant="contained"
										sx={{ mr: 2 }}
									>
										CREATE GROUP
									</Button>
									<Dropdown align="end">
										<Dropdown.Toggle variant="success" id="dropdown-basic">
											{dataType === "default" ? "Data Type Filter" : dataType}
										</Dropdown.Toggle>
										<Dropdown.Menu style={{ width: "200px" }}>
											<Dropdown.Item onClick={() => setDataType("default")}>
												Default
											</Dropdown.Item>
											<Dropdown.Item onClick={() => setDataType("+30days")}>
												Have order in 30 days
											</Dropdown.Item>
											<Dropdown.Item onClick={() => setDataType("-30days")}>
												No order in 30 days
											</Dropdown.Item>
											{userGroups.length > 0 &&
												userGroups?.map((el, index) => {
													return (
														<Dropdown.Item
															key={index}
															onClick={() => setDataType(el.group_name)}
														>
															{el.group_name}
														</Dropdown.Item>
													);
												})}
										</Dropdown.Menu>
									</Dropdown>
								</Box>
							</Box>

							<Box>
								{dataType === "default" && (
									<Table data={income.userPoints} userGroups={userGroups} />
								)}

								{dataType === "+30days" && (
									<Table
										data={income.userHaveOrderIn30days}
										userGroups={userGroups}
									/>
								)}

								{dataType === "-30days" && (
									<Table
										data={income.noOrderFor30days}
										userGroups={userGroups}
									/>
								)}
								{userGroups?.map(
									(el, index) =>
										dataType === el.group_name && (
											<Table
												key={index}
												data={income?.userPoints.filter(
													(user) => user.user_group === el.id
												)}
												userGroups={userGroups}
											/>
										)
								)}
							</Box>
						</Box>
						<Modal
							open={show}
							onClose={hideModal}
							sx={{
								margin: "auto",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Paper>
								<Form style={{ margin: "15px", width: "350px" }}>
									<Form.Group className="mb-3">
										<Form.Label>Available Groups</Form.Label>
									</Form.Group>
									<Form.Group>
										{userGroups?.map((el, index) => {
											return (
												<ol
													key={index}
													style={{ color: "white", fontSize: "20px" }}
												>
													<li>
														{el.group_name.toUpperCase()}
														<span style={{ float: "right" }}>
															<Button
																style={{
																	backgroundColor: "red",
																	border: "none",
																	color: "white",
																	fontSize: "15px",
																	padding: "5px 10px",
																}}
																onClick={() => deleteGroup(el.id)}
															>
																DELETE
															</Button>
														</span>
													</li>
												</ol>
											);
										})}
									</Form.Group>
									<Divider sx={{ my: 4, borderColor: "white" }} />
									<Form.Group className="mb-3">
										<Form.Label>CREATE GROUP</Form.Label>
									</Form.Group>
									<Form.Group className="mb-3">
										<TextField
											placeholder="Group Name"
											onChange={(e) => setGroupName(e.target.value)}
										/>
									</Form.Group>

									<Button variant="contained" onClick={createUserGroup}>
										CREATE
									</Button>
								</Form>
							</Paper>
						</Modal>
					</ThemeProvider>
				</Grid>
			</Grid>
		</div>
	);
}
