import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Disclaimer(props) {
	const { onClose } = props;
	const navigate = useNavigate();
	const handleUnder21Click = () => {
		// Redirect to https://google.com when "Under 21" button is clicked
		window.location.href = "https://google.com";
	};
	return (
		<div className="popup">
			<div style={{ backgroundColor: "rgb(9, 65, 0)", color: "white" }}>
				<h4>AGE VERIFICATION</h4>
			</div>
			<h4 id="disclaimerH3budz">H3budz</h4>
			<p>MUST BE OVER 21 YEARS OR OLDER</p>
			<p>
				The products available on h3budz.com are age-restricted and intended for
				adults of legal smoking age only. All orders placed on the website will
				be verified by an industry leading Age Verification software for
				validation
			</p>
			<Button
				variant="contained"
				style={{
					backgroundColor: "rgb(9, 65, 0)",
					marginRight: "10px",
				}}
				onClick={onClose}
			>
				I'm 21 +
			</Button>
			<Button
				variant="contained"
				style={{ background: "red", marginLeft: "10px" }}
				onClick={handleUnder21Click}
			>
				Under 21
			</Button>
		</div>
	);
}
