import { createContext, useState, useEffect } from "react";
import axios, { Axios } from "axios";
import { url } from "./url";

export const ProductContext = createContext();
export function ProductContextProvider({ children }) {
	const [products, setProducts] = useState([]);
	const [additionalImages, setAdditionalImages] = useState([])
	const [featureProducts, setFeatureProducts] = useState([])
	const [storageData, setStorageData] = useState([]);
	const [category, setCategory] = useState([]);
	const [paymentType, setPaymentType] = useState("")
	const [deleteConfirmation, setDeleteConfirmation] = useState(false)
	const [shippingMethod] = useState([
		{ id: 1, label: "STANDARD", fees: 0, note: "$1 = 1.5 PT" },
		{ id: 2, label: "EXPRESS", fees: 0, note: "" },
		{ id: 3, label: "EXPEDITED", fees: 15, note: "" },
	]);
	const [tipsMethod] = useState([
		{ id: 1, label: '---', multiplyer: 1.00 },
		{ id: 2, label: '10%', multiplyer: 0.10 },
		{ id: 3, label: '15%', multiplyer: 0.15 },
		{ id: 4, label: '20%', multiplyer: 0.20 },
		{ id: 5, label: '25%', multiplyer: 0.25 },

	])

	const [paymentMethod] = useState([
		{ id: 1, label: 'Credit Card' },
		{ id: 2, label: 'Venmo' }
	])
	const [selectedMethod, setSelectedMethod] = useState({});
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
	const [itemCount, setItemCount] = useState(0);
	const [total, setTotal] = useState(0);
	const [subtotal, setSubtotal] = useState(0);
	const [salesTax, setSalesTax] = useState(0);
	const [delivery, setDelivery] = useState(0);
	const [tips, setTips] = useState(0)
	const tax = 0.0888;
	const [temperPoints, setTemperPoints] = useState(0)
	useEffect(() => {
		setStorageData(JSON.parse(localStorage.getItem("h3")));
	}, [itemCount]);

	useEffect(() => {
		setSelectedMethod(tipsMethod[0]);
		setSelectedPaymentMethod(paymentMethod[0])

	}, [storageData]);

	const getTotal = () => {
		let totalPrice;
		let count;
		if (cartItem) {
			totalPrice = cartItem.reduce((acc, cur) => {
				if (acc === 0) return cur.item.price * cur.qt;
				return acc + cur.qt * cur.item.price;
			}, 0);
			count = cartItem.reduce((acc, cur) => {
				return acc + cur.qt;
			}, 0);
			setItemCount(count);

			totalPrice < 50
				? setDelivery(10)
				: setDelivery(0);
			const preTax = totalPrice + delivery;
			const orderTotal = preTax + salesTax;
			setSubtotal(totalPrice);
			// console.log(Math.ceil(preTax * tax * 100) / 100);
			// setSalesTax(parseFloat((preTax * tax).toFixed(2)));
			setSalesTax(Math.ceil(preTax * tax * 100) / 100);
			if (selectedMethod.id !== 1) {
				setTips(parseFloat(preTax * selectedMethod.multiplyer))
			} else {
				setTips(0)
			}
			selectedMethod.id === 1 ? setTotal(orderTotal) : setTotal(parseFloat((orderTotal + preTax * selectedMethod.multiplyer).toFixed(2)))
		} else {
			return <p>no item</p>;
		}
	};

	function addToCart(item) {
		const existingData = storageData;
		const selectedItem = existingData.find(
			(el) => el.item.id === item.id && el.option === item.option,
		);
		if (selectedItem && selectedItem.option === item.option) {
			selectedItem.qt += 1;
			setStorageData([...existingData]); // Update the state with the updated data
			localStorage.setItem("h3", JSON.stringify(existingData)); // Store the updated state in localStorage
		}
	}
	function removeFromCart(item) {
		const existingData = JSON.parse(localStorage.getItem("h3"));
		const updatedData = existingData.map((el) => {
			if (el.item.id === item.id && el.option === item.option) {
				el.qt -= 1;
				if (el.qt === 0) {
					return null; // Mark the object for removal
				}
			}
			return el;
		});

		const filteredData = updatedData.filter((el) => el !== null);
		setStorageData(filteredData);
		localStorage.setItem("h3", JSON.stringify(filteredData));
		getTotal();
	}

	const getProducts = async () => {
		try {
			const response = await axios.get(url + "/all_products");
			setProducts(response.data);

			let x = [];
			response.data.forEach((el) => x.push(el.category));

			let unique = x.reduce((acc, curr) => {
				if (!acc.includes(curr)) acc.push(curr);
				return acc;
			}, []);
			setCategory(unique);
			return response.data; // Returning the fetched products directly
		} catch (error) {
			console.error("Error fetching products:", error);
			return []; // Return an empty array in case of an error
		}
	};

	const getFeatureProducts = async (txt) => {
		try {
			const response = await axios.get(url + `/feature_products/${txt}`)
			const data = response.data
			setFeatureProducts(data)
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}


	const deleteProduct = async (id) => {
		await axios.delete(url + "/product", { data: { id: id } });
	};

	const deleteAdditionalImage = async (id) => {
		await axios.delete(url + '/delete_additional_image', { data: { data: id } })
		setDeleteConfirmation(false)
	}
	const getAdditionalImages = async (id) => {
		try {
			const result = await axios.get(url + `/get_additional_images/${id}`);
			setAdditionalImages(result.data);
			setDeleteConfirmation(true);
			return result.data;
		} catch (error) {
			console.error("Error fetching additional images:", error);
			throw error; // Propagate error for higher-level handling
		}
	};

	const getProductById = async (id) => {
		try {
			const result = await axios.get(url + `/product/${id}`);
			return result.data; // Properly return the data here
		} catch (error) {
			console.error("Error fetching product data:", error);
			return undefined; // Handle error by returning undefined or any fallback data
		}
	};

	// const getCategory = async () => {
	// 	await axios.get(url + "/all_products").then((response) => {
	// 		let x = [];
	// 		response.data.map((el) => x.push(el.category));
	// 		let unique = x.reduce((acc, curr) => {
	// 			if (!acc.includes(curr)) acc.push(curr);
	// 			return acc;
	// 		}, []);
	// 		setCategory(unique);
	// 	});
	// 	return category;
	// };

	let cartItem = [];
	cartItem = storageData;

	return (
		<ProductContext.Provider
			value={{
				getProducts,
				// getCategory,
				getTotal,
				deleteProduct,
				setProducts,
				setItemCount,
				setTotal,
				setSubtotal,
				setSalesTax,
				setDelivery,
				products,
				cartItem,
				itemCount,
				category,
				storageData,
				subtotal,
				total,
				salesTax,
				delivery,
				addToCart,
				removeFromCart,
				setDelivery,
				shippingMethod,
				selectedMethod,
				setSelectedMethod,
				temperPoints,
				setTemperPoints,
				tipsMethod,
				tips,
				paymentMethod,
				selectedPaymentMethod,
				paymentType,
				setSelectedPaymentMethod,
				getFeatureProducts,
				featureProducts,
				additionalImages,
				setAdditionalImages,
				getAdditionalImages,
				deleteAdditionalImage,
				deleteConfirmation,
				setDeleteConfirmation,
				getProductById
			}}
		>
			{children}
		</ProductContext.Provider>
	);
}

export default ProductContextProvider;
