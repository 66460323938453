import React, { useState } from 'react';

export default function AdditionalImagesUpload({ onImagesUpload, selectedImages, setSelectedImages }) {

    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedImages((prevImages) => [...prevImages, ...files]);
    };

    const handleRemoveImage = (index) => {
        setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    const handleUpload = () => {
        if (onImagesUpload) {
            onImagesUpload(selectedImages);
        }
    };

    return (
        <div>
            <input
                type="file"
                accept="image/*"
                multiple
                onChange={handleImageChange}
            />
            <div>
                {selectedImages?.map((image, index) => (
                    <div key={index} style={{ display: 'inline-block', margin: '10px' }}>
                        <img
                            src={URL.createObjectURL(image)}
                            alt={`preview-${index}`}
                            width="100"
                            height="100"
                        />
                        <button onClick={() => handleRemoveImage(index)}>Remove</button>
                    </div>
                ))}
            </div>
            {
                // <button onClick={handleUpload}>Upload</button>
            }
        </div>
    );
}
