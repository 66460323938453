import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import h3Logo from "./images/h3logo.png";
import MailBadge from "./components/MailBadge";
import axios from "axios";

import { AuthContext } from "./AuthContext";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import LoginSharpIcon from "@mui/icons-material/LoginSharp";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";

import CartBadge from "./components/CartBadge";
import CartPopUp from "./components/CartPopUp";
import { ProductContext } from "./ProductContext";
import { MessageContext } from "./MessageContext";

import { LanguageContext } from "./controller/LanguageContext";
import { Switch } from "@mui/material";
import en from "./images/en.png";
import cn from "./images/cn.png";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const pages = ["Home", "Shop", "Contact"];
const settings = ["Profile", "Order History", "Logout"];
const pagesCn = ["主頁", "商店", "聯係我們"];

function Navigation(props) {
	const navigate = useNavigate();
	const { fetchMessage, messageReceived } = useContext(MessageContext);
	const { logout } = useContext(AuthContext);
	const { itemCount } = useContext(ProductContext);
	const { currentUser, setMessages } = props;
	const [cartOpen, setCartOpen] = useState(false);
	const cartItems = JSON.parse(localStorage.getItem("h3"));
	const { isEn, changeLanguage } = useContext(LanguageContext);

	// const [messageReceived, setMessageReceived] = useState(0);

	const [anchorElNav, setAnchorElNav] = useState(null);
	const [anchorElUser, setAnchorElUser] = useState(null);
	const [openDrawer, setOpenDrawer] = useState(false);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};
	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};
	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleOpen = () => {
		setCartOpen(true);
	};

	const handleClose = () => {
		setCartOpen(false);
		// window.location.reload();
	};

	const toPath = (name) => {
		setCartOpen(false);
		navigate("/" + name);
	};

	const toggleDrawer = (newOpen) => () => {
		setOpenDrawer(newOpen);
	};

	const theme = createTheme({
		components: {
			MuiBox: {
				styleOverrides: {
					root: {
						width: "200px",
					},
				},
			},
		},
	});

	return (
		<ThemeProvider theme={theme}>
			<p>{messageReceived}</p>
			<AppBar
				position="fixed"
				sx={{
					backgroundColor: "black",
					boxShadow: `-3px 4px 10px -3px #094100`,
					zIndex: 1,
					top: 0,
				}}
			>
				<div id="greenBanner">
					{!isEn ? (
						<p>FREE DELIVERY FOR ORDERS OVER $50</p>
					) : (
						<p>消費滿$50免費送貨</p>
					)}
				</div>
				<Container maxWidth="xl">
					<Toolbar disableGutters>
						<a href="https://h3budz.com" className="hideMobile">
							<img src={h3Logo} style={{ width: "85px", float: "left" }} />
						</a>
						<Box
							sx={{
								flexGrow: 1,
								display: { xs: "flex", md: "none" },
							}}
						>
							<IconButton
								size="large"
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={toggleDrawer(true)}
								sx={{ color: "white" }}
							>
								<MenuIcon />
							</IconButton>

							<Drawer open={openDrawer} onClose={toggleDrawer(false)}>
								<Box
									sx={{
										width: 250,
										backgroundColor: "black",
										height: "100vh",
										padding: "15px",
									}}
									role="presentation"
									onClick={toggleDrawer(false)}
								>
									<List>
										{pages.map((page) => (
											<MenuItem
												key={page}
												sx={{
													backgroundColor: "none",
													borderBottom: "1px solid white",
												}}
												onClick={() => {
													toPath(page);
													handleCloseNavMenu();
												}}
											>
												<Typography textAlign="center" sx={{ color: "white" }}>
													{page}
												</Typography>
											</MenuItem>
										))}
									</List>
									<List>
										<div style={{ float: "right" }}>
											<Switch
												onChange={changeLanguage}
												style={{ color: "green" }}
												color="primary"
											></Switch>
											<img src={isEn ? cn : en} style={{ width: "35px" }} />
										</div>
									</List>
								</Box>
							</Drawer>
							<a href="https://h3budz.com">
								<img src={h3Logo} style={{ width: "85px", float: "left" }} />
							</a>
						</Box>

						<Typography
							style={{ margin: "0" }}
							sx={{
								display: {
									xs: "none",
									md: "block",
								},
							}}
						>
							<span
								style={{
									fontFamily: "Vollkorn",
									color: "#094100",
									fontSize: "30px",
								}}
							>
								<strong>H3BUDZ</strong>
							</span>
						</Typography>

						<Box
							sx={{
								flexGrow: 1,
								display: { xs: "none", md: "flex" },
							}}
						>
							{!isEn
								? pages.map((page) => (
										<Button
											key={page}
											onClick={() => {
												toPath(page);
											}}
											sx={{
												my: 2,
												color: "white",
												display: "block",
											}}
										>
											{page}
										</Button>
								  ))
								: pagesCn.map((page) => (
										<Button
											key={page}
											onClick={() => {
												if (page === "主頁") toPath("Home");
												if (page === "商店") toPath("Shop");
												if (page === "聯係我們") toPath("Contact");
											}}
											sx={{
												my: 2,
												color: "white",
												display: "block",
											}}
										>
											{page}
										</Button>
								  ))}
						</Box>

						{/* <div className="hideMobile">
							<Switch onChange={changeLanguage} color="primary"></Switch>
							<img src={isEn ? cn : en} style={{ width: "35px" }} />
						</div> */}
						<Box sx={{ flexGrow: 0 }}>
							{currentUser?.success ? (
								<span>
									<Tooltip title="Open settings">
										<IconButton
											onClick={handleOpenUserMenu}
											sx={{ padding: "8px" }}
										>
											<h3 style={{ color: "white", margin: "0 " }}>
												{currentUser.data.firstName[0].toUpperCase()}
											</h3>
										</IconButton>
									</Tooltip>
								</span>
							) : (
								<Button
									style={{
										color: "white",
										// border: "2px solid #094100",
										// backgroundColor: "#094100",
										// marginRight: "5px",
									}}
									onClick={() => navigate("/loginForm")}
								>
									<LoginSharpIcon />
								</Button>
							)}
							<Menu
								sx={{ mt: "45px" }}
								id="menu-appbar"
								anchorEl={anchorElUser}
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								open={Boolean(anchorElUser)}
								onClose={handleCloseUserMenu}
							>
								{currentUser.data?.role === "admin" ? (
									<MenuItem onClick={() => navigate("/dashboard")}>
										Dashboard
									</MenuItem>
								) : (
									<div></div>
								)}
								{settings.map((setting) => (
									<MenuItem
										key={setting}
										onClick={() => {
											if (setting === "Logout") logout();
											if (setting === "Profile") navigate("/profile");
											if (setting === "Order History")
												navigate("/order-history");
										}}
									>
										<Typography textAlign="center">{setting}</Typography>
									</MenuItem>
								))}
							</Menu>
						</Box>

						<span onClick={() => navigate("/messages")}>
							<MailBadge badgeContent={messageReceived} />
						</span>

						<span onClick={handleOpen}>
							<CartBadge itemCount={itemCount} />
						</span>
						<CartPopUp
							handleClose={handleClose}
							cartOpen={cartOpen}
							cartItem={cartItems}
							currentUser={currentUser}
						/>
					</Toolbar>
				</Container>
			</AppBar>
		</ThemeProvider>
	);
}

export default Navigation;
