import React, { forwardRef, useState } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars(props) {
	const { message, open, handleClick, setOpen, color } = props;
	const [state, setState] = useState({
		open: open,
		vertical: "top",
		horizontal: "center",
	});

	const { vertical, horizontal } = state;

	//=============================================
	// move this to parant to use

	// const [open, setOpen] = useState(false);
	// const handleClick = () => {
	// 	setOpen(true);
	// };
	//---------------------------------------

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return setOpen(false);
		}
		setOpen(false);
	};

	return (
		<Stack spacing={2} sx={{ width: "100%", top: "50px" }}>
			{/*
                <Button variant="outlined" onClick={handleClick}>
                    Open success snackbar
                </Button>
                 */}
			<Snackbar
				open={open}
				autoHideDuration={3000}
				onClose={handleClose}
				anchorOrigin={{ vertical, horizontal }}
			>
				<Alert onClose={handleClose} severity={color} sx={{ width: "100%" }}>
					{message}
				</Alert>
			</Snackbar>
		</Stack>
	);
}
