import React, { useEffect, useState } from "react";
import { url } from "../url";
import axios from "axios";
import { Button, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import AlertPopUp from "./AlertPopUp";
import CustomizedAccordions from "./CustomizedAccordions";
import h3Logo from "../images/h3logo.png";
import HistoryAccordion from "./HistoryAccordions";
import Dropdown from "react-bootstrap/Dropdown";
import SummarizeIcon from "@mui/icons-material/Summarize";
import CircularProgress from "@mui/material/CircularProgress";

export default function OrderSubmitPage(props) {
	const { currentUser } = props;
	const navigate = useNavigate();

	const [open, setOpen] = useState(false);
	const [color, setColor] = useState("");
	const [message, setMessage] = useState("");

	const [orders, setOrders] = useState([]);
	const [orderHistory, setOrderHistory] = useState([]);
	const [keywords, setKeywords] = useState("");
	const [paymentLink, setPaymentLink] = useState("");
	const [allPayments, setAllPayments] = useState([]);
	const historyFilter = ["", "pending", "completed"];
	const [isLoading, setIsLoading] = useState(true);

	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;

	const handleClick = (txt, color) => {
		setOpen(true);
		setMessage(txt);
		setColor(color);
	};

	const getPaymentLink = async () => {
		axios.get(url + "/payment_link").then((response) => {
			setAllPayments(response.data);
		});
	};

	const filteredByStatus = orderHistory.filter((order) => {
		const keyword = keywords.toLocaleLowerCase();
		const orderNumber = order.orderNumber.toLocaleLowerCase();

		return (
			orderNumber.includes(keyword) ||
			order.createdAt.includes(keyword) ||
			order.status.includes(keyword)
		);
	});

	const filterByType = (keywords) => {
		setKeywords(keywords);
	};

	const confirmPaymentComplete = (userId, orderNumber) => {
		axios.post(url + "/confirm_payment_complete", {
			userId: parseInt(userId),
			orderNumber: orderNumber,
		});
		handleClick("Transaction Completed");
		setTimeout(() => {
			window.location.reload();
		}, 2000);
	};

	const getOrderHistory = async () => {
		setIsLoading(true); // Start loading
		try {
			const response = await axios.get(url + "/order_history");
			setOrderHistory(response.data);
		} catch (error) {
			console.error("Error fetching order history:", error);
		} finally {
			setIsLoading(false); // End loading
		}
	};

	useEffect(() => {
		getOrderHistory();
	}, []);

	// Calculate the data to display based on pagination
	const paginatedData = filteredByStatus.slice(
		(currentPage - 1) * itemsPerPage,
		currentPage * itemsPerPage
	);

	const totalPages = Math.ceil(filteredByStatus.length / itemsPerPage);

	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};

	return (
		<div className="container">
			<AlertPopUp
				open={open}
				setOpen={setOpen}
				message={message}
				handleClick={handleClick}
				color={color}
			/>
			<SearchIcon
				sx={{
					color: "white",
					display: window.innerWidth < 600 ? "none" : "inline",
				}}
			/>
			<input
				placeholder="Search With Order #, Date ..."
				onChange={(e) => setKeywords(e.target.value)}
				style={{
					margin: "15px",
					width: window.innerWidth < 600 ? "90%" : "80%",
				}}
			></input>
			<div
				className="d-flex"
				style={{
					margin: "25px 5px",
					justifyContent: "flex-end",
				}}
			>
				<Dropdown>
					<Dropdown.Toggle
						variant="success"
						id="dropdown-basic"
						style={{
							width: "150px",
							backgroundColor: "rgb(9, 65, 0)",
							border: "none",
							fontWeight: "bold",
							borderRadius: "0",
						}}
					>
						Filter
					</Dropdown.Toggle>

					<Dropdown.Menu style={{ width: "150px" }}>
						<Dropdown.Item onClick={() => filterByType("")}>ALL</Dropdown.Item>
						{historyFilter.map((el, index) => {
							return (
								<Dropdown.Item key={index} onClick={() => filterByType(el)}>
									{el.toUpperCase()}
								</Dropdown.Item>
							);
						})}
					</Dropdown.Menu>
				</Dropdown>
			</div>

			{!isLoading ? (
				<>
					{paginatedData.length !== 0 ? (
						paginatedData.map((el, index) => (
							<HistoryAccordion
								data={el}
								key={index}
								currentUser={currentUser}
								getOrderHistory={getOrderHistory}
							/>
						))
					) : (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								color: "white",
								height: "50vh",
								width: "auto",
							}}
						>
							No Data Found
						</div>
					)}
					<div className="pagination-controls">
						{Array.from({ length: totalPages }, (_, index) => {
							const pageIndex = index + 1;
							const isDisabled =
								filteredByStatus.slice(
									(pageIndex - 1) * itemsPerPage,
									pageIndex * itemsPerPage
								).length === 0;

							return (
								<Button
									key={index}
									variant={currentPage === pageIndex ? "contained" : "outlined"}
									onClick={() => handlePageChange(pageIndex)}
									style={{ margin: "35px 0 100px 0" }}
									disabled={isDisabled}
									color="success"
								>
									{pageIndex}
								</Button>
							);
						})}
					</div>
				</>
			) : (
				<CircularProgress color="success" />
			)}
		</div>
	);
}
