import React, { useEffect, useState } from "react";
import LoadingBar from "./LoadingBar";
import { Bar } from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	Tooltip,
	Legend,
	BarElement,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, Tooltip, Legend, BarElement);

export default function PieChart(props) {
	const { datas, itemName, textSize, height } = props;
	const [isLoading, setIsLoading] = useState(false);

	const [chartData, setChartData] = useState({
		labels: [],
		datasets: [
			{
				label: "Sold",
				data: [],
				backgroundColor: [],
				hoverOffset: 50,
			},
		],
	});

	useEffect(() => {
		if (datas && Object.keys(datas).length > 0) {
			setIsLoading(true);
			// Map titles and counts to chart data
			const labels = Object.values(datas).map((item) =>
				item.title.toUpperCase()
			);
			const data = Object.values(datas).map((item) => item.count);
			const sortedData = [...data].sort((a, b) => b - a); // Sort in descending order
			const topThree = sortedData.slice(0, 3); // Get the top 3 values

			// Assign colors based on whether each value is in the top 3
			const colors = data.map((value) => {
				if (value === topThree[0]) return "#f62aa0"; // Highest gets "rgb(9, 65, 0)"
				if (value === topThree[1]) return "#b8ee30"; // Second highest gets "black"
				if (value === topThree[2]) return "#26dfd0";
				return "#0a7029";
			});

			setChartData({
				labels,
				datasets: [
					{
						label: itemName,
						data,
						backgroundColor: colors,
					},
				],
			});
			setIsLoading(false);
		}
	}, [datas, itemName]);
	const customLegendTextColorPlugin = {
		id: "customLegendTextColor",
		beforeDraw(chart) {
			const ctx = chart.ctx;
			chart.legend.legendItems.forEach((legendItem) => {
				legendItem.fontColor = "white"; // Set legend text color to white
			});
		},
	};

	const chartOptions = {
		responsive: true, // Make chart responsive
		indexAxis: "y", // Set the bar chart to horizontal orientation
		maintainAspectRatio: true,
		scales: {
			y: {
				ticks: {
					overflow: "scroll",
					display: window.innerWidth > 300,
					autoSkip: false, // Ensures all labels are displayed
					color: "white",

					font: {
						family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
						style: "italic",
						weight: "bold",
						size: parseInt(textSize), // Adjust font size if needed to fit all labels
					},
				},
			},
		},
		plugins: {
			legend: {
				position: "top", // Position the legend (top, bottom, left, right)
				maintainAspectRatio: true,
				labels: {
					color: "white", // Set legend text color to white
					generateLabels: function (chart) {
						const { data } = chart;
						const topThreeIndices = [...data.datasets[0].data]
							.map((value, index) => ({ value, index }))
							.sort((a, b) => b.value - a.value)
							.slice(0, 3)
							.map((item) => item.index);

						return topThreeIndices.map((index) => ({
							text: `${data.labels[index]}`, // Define a custom name for each top item
							fillStyle: data.datasets[0].backgroundColor[index],
						}));
					},
					boxWidth: 10, // Width of the color box next to each label
					font: {
						size: window.innerWidth < 600 ? 10 : 14, // Font size for legend labels
						family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
						style: "italic",
						weight: "bold",
						color: "white",
					},
					padding: 12, // Padding around legend text
				},
			},
			tooltip: {
				enabled: true, // Enable tooltips
				backgroundColor: "rgba(0, 0, 0, 0.7)", // Background color of tooltip
				bodyFont: {
					size: 12, // Font size for tooltip text
				},
				borderColor: "#ddd", // Border color of the tooltip
				borderWidth: 1,
				padding: 10, // Padding inside the tooltip
				boxPadding: 5, // Padding outside the tooltip box
			},
		},
	};

	return (
		<div
			className=" "
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				// width: "auto",
				// overflow: "scroll",
			}}
		>
			{isLoading ? (
				<LoadingBar />
			) : (
				<Bar
					data={chartData}
					options={chartOptions}
					plugins={[customLegendTextColorPlugin]}
					height={height}
				/>
			)}
		</div>
	);
}
