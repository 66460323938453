import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function LoadingBar() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh', // Takes full height of the viewport
                width: '100vw',  // Takes full width of the viewport
            }}
        >
            <CircularProgress color="success" />
        </Box>
    );
}
