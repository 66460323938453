import axios from "axios";
import { useLocation } from "react-router-dom";
import { React, useContext, useEffect, useState } from "react";
import { Form, Container, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useProductById } from "../service/productService";
import { ProductContext } from "../ProductContext";
import { Button } from "@mui/material";
import ConfirmationPopup from "./ConfirmationPopup";
import AnimationSharpIcon from "@mui/icons-material/AnimationSharp";
import { url } from "../url";
import Swiper from "./SwiperNew"
import leaves from "../images/Leaves.png";
import LoadingBar from "./LoadingBar";


export default function ProductDetail() {
	const [product, setProduct] = useState({});
	const { itemCount, setItemCount, cartItem, getAdditionalImages, additionalImages, setAdditionalImages } = useContext(ProductContext);
	const { id } = useParams();
	const { isLoading, data } = useProductById(id);
	const [quantity, setQuantity] = useState(1);
	const [option, setOption] = useState("default");
	const [recommand, setRecommand] = useState([])
	const [openConFirmationPopup, setOpenConfirmationPopup] = useState(false);
	const [additionalPhotos, setAdditionalPhotos] = useState([])



	const addToCartAlert = () => setOpenConfirmationPopup(true);

	const [imageList, setImageList] = useState([])
	const [currentImage, setCurrentImage] = useState(product.url);

	const handleImageClick = (imageUrl) => {
		setCurrentImage(imageUrl);
	};

	const [cart, setCart] = useState(
		JSON.parse(localStorage.getItem("h3")) || [],
	);
	const addToCart = (checkedItem) => {
		setCart(() => {
			const isInCart = cartItem.find(
				(item) =>
					item.id === checkedItem.id && item.option === checkedItem.option,
			);
			if (isInCart) {
				return cartItem.map((item) => {
					return item.id === checkedItem.id &&
						item.option === checkedItem.option
						? { ...item, qt: item.qt + parseInt(quantity) }
						: item;
				});
			}
			return [...cartItem, { ...checkedItem, qt: parseInt(quantity) }];
		});
		setItemCount(itemCount + checkedItem.qt);
		addToCartAlert();
	};

	const sortOption = product.options?.sort((a, b) =>
		a.label.localeCompare(b.label),
	);



	useEffect(() => {
		if (data) {
			setProduct(data);
			axios.get(url + '/get_products_by_category', { params: { keyword: data.category } }).then((response) => {
				setRecommand(response.data.filter(item => item.title !== data.title))
			})
			setImageList([data.url])
			setCurrentImage(data.url)
		}
	}, [data]);

	useEffect(() => {
		localStorage.setItem("h3", JSON.stringify(cart));
	}, [cart]);


	useEffect(() => {
		if (data) {
			const fetchAdditionalPhotos = async () => {
				try {
					const result = await axios.get(url + `/get_additional_images/${data.id}`);
					const additionalPhotos = result.data;

					const originalUrl = data.url ? [{ url: data.url }] : []; // Create an array with original URL if it exists

					// Update state with both the original URL and the fetched photos
					setAdditionalPhotos([...originalUrl, ...additionalPhotos]);
				} catch (error) {
					console.error("Error fetching additional images:", error);
				}
			};

			fetchAdditionalPhotos();
		}
	}, [data]);

	if (isLoading) {
		return <LoadingBar />;
	}

	return (
		<Container>
			<ConfirmationPopup
				openConFirmationPopup={openConFirmationPopup}
				setOpenConfirmationPopup={setOpenConfirmationPopup}
			/>

			<div className="productDetailContainer">
				<div id="productDetailImgContainer">
					<img src={currentImage} style={{ maxWidth: '350px' }} alt={product.title} />
					<div style={{ marginTop: '10px' }}>

						{additionalPhotos?.map((el, index) => {
							return <img src={el.url} key={index} className='additionalImage' onClick={() => setCurrentImage(el.url)} />

						})}
					</div>
				</div>
				<div style={{ margin: "auto", textAlign: "left", width: "40%" }} id="productDetailContant">
					{product.sale === true ? (
						<p
							style={{
								background: "green",
								borderRadius: "13px",
								width: "70px",
								color: "white",
								textAlign: "center",
								position: "absolute",
							}}
						>
							SALE
						</p>
					) : (
						""
					)}
					<br></br>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<h5 style={{ color: "white" }}>
							{product?.title?.toUpperCase()}
						</h5>
						<h3 style={{ color: "white" }}>${product.price}</h3>
					</div>
					<p style={{ color: "white", textAlign: 'right' }}>
						<AnimationSharpIcon sx={{ color: "gold" }} />
						{product.points_needed}
					</p>
					<p style={{ color: "white" }}>
						{product?.description?.toUpperCase()}
					</p>
					{
						<Form>
							<Row className="mb-3">
								<Form.Group as={Col} controlId="formGridOption">
									<Form.Label>
										<p style={{ color: "white", fontWeight: 'normal', margin: 0 }}>OPTION</p>
									</Form.Label>
									<Form.Select
										aria-label="Default select example"
										value={option}
										onChange={(e) => setOption(e.target.value)}
										style={{ border: 'none' }}
									>
										<option value="default" disabled>
											Select an option
										</option>
										{product.options?.length > 0 ? (
											<>
												{sortOption?.map((el, index) =>
													el.count > 0 ? (
														<option key={index} value={el.label}>
															{el.label?.toUpperCase()}
														</option>
													) : (
														<option
															key={index}
															value={el.label}
															disabled
															style={{ color: "orange" }}
														>
															{el.label?.toUpperCase()}{" "}
															{el.count === 0 ? " - Out of stock" : ""}
														</option>
													),
												)}
											</>
										) : (
											<option value="default" disabled>
												Not Available
											</option>
										)}
									</Form.Select>
								</Form.Group>
							</Row>
						</Form>
					}
					<p style={{ color: "white", marginBottom: '8px' }}>QT</p>
					<Form.Group>
						<input
							type="number"
							name="Quantity"
							onChange={(e) => setQuantity(e.target.value)}
							style={{ width: "150px", textAlign: "center", borderRadius: '5px', border: 'none' }}
							value={quantity}
						/>
					</Form.Group>
					<Button
						variant="contained"
						disabled={
							!option ||
							option === "default" ||
							product.options?.every((el) => el.count === 0)
						}
						style={{
							width: "100%",
							margin: "55px 0",
							color: "white",
							backgroundColor:
								!option ||
									option === "default" ||
									product.options?.every((el) => el.count === 0)
									? "gray"
									: "#094100",
						}}
						onClick={() =>
							addToCart({
								item: product,
								option:
									option ||
									(product.options?.length > 0 ? product.options[0].label : ""),
								qt: parseInt(quantity),
								id: product.id,
								redeem: false,
							})
						}
					>
						Add
					</Button>
				</div>
			</div>


			{
				recommand?.length ?
					<div style={{ marginTop: '25px' }}>
						<div style={{
							backgroundImage: `url(${leaves})`,
							height: "50px",
							boxShadow: "inset 0 0 10px #000000",
							margin: '35px 0'
						}}>
							<p style={{ color: 'white', fontWeight: 'bold', padding: '10px 20px' }}>
								Frequently Bought Together</p>


						</div>
						< Swiper data={recommand} />
					</div> :
					null
			}

		</Container>
	);
}
