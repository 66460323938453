import { React, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { url } from "../url";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBitcoin, faChrome } from "@fortawesome/free-brands-svg-icons";
import { Paper, Button, Box, Grid } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function Profile(props) {
	const { currentUser } = props;
	const navigate = useNavigate();
	const [firstName, setFirstName] = useState(currentUser.data.firstName);
	const [lastName, setLastName] = useState(currentUser.data.lastName);
	const [email, setEmail] = useState(currentUser.data.email);
	const [address, setAddress] = useState(currentUser.data.address);
	const [city, setCity] = useState(currentUser.data.city);
	const [state, setState] = useState(currentUser.data.state);
	const [zipcode, setZipcode] = useState(currentUser.data.zipcode);
	const [phone, setPhone] = useState(currentUser.data.phone);

	const updateUser = async (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("firstName", firstName);
		formData.append("lastName", lastName);
		formData.append("email", email);
		formData.append("address", address);
		formData.append("city", city);
		formData.append("state", state);
		formData.append("zipcode", zipcode);
		formData.append("phone", phone);
		formData.append("id", currentUser.data.userId);

		axios.put(url + "/updateUserById", formData).then((err, response) => {
			alert("Profile updated!");
			navigate("/");
		});
	};
	const theme = createTheme({ palette: { mode: "dark" } });

	const formFields = [
		{
			label: "First Name",
			type: "text",
			value: firstName,
			setter: setFirstName,
			id: "formGridFirstName",
		},
		{
			label: "Last Name",
			type: "text",
			value: lastName,
			setter: setLastName,
			id: "formGridLastName",
		},
		{
			label: "Email",
			type: "email",
			value: email,
			setter: setEmail,
			id: "formGridEmail",
		},
		{
			label: "Address",
			type: "text",
			value: address,
			setter: setAddress,
			id: "formGridAddress1",
		},
		{
			label: "City",
			type: "text",
			value: city,
			setter: setCity,
			id: "formGridCity",
		},
		{
			label: "State",
			type: "text",
			value: state,
			setter: setState,
			id: "formGridState",
		},
		{
			label: "Zip",
			type: "text",
			value: zipcode,
			setter: setZipcode,
			id: "formGridZip",
		},
		{
			label: "Phone",
			type: "text",
			value: phone,
			setter: setPhone,
			id: "formGridPhone",
		},
	];

	const renderFormGroup = ({ label, type, value, setter, id }) => (
		<Form.Group as={Col} controlId={id} key={id}>
			<Form.Label>{label}</Form.Label>
			<Form.Control
				type={type}
				placeholder={label}
				value={value}
				onChange={(e) => setter(e.target.value)}
			/>
		</Form.Group>
	);

	return (
		<Grid
			container
			spacing={2}
			className="d-flex"
			style={{ justifyContent: "center" }}
		>
			<ThemeProvider theme={theme}>
				<Box className="container">
					<Paper sx={{ margin: "25px 0", padding: "15px" }} elevation={1}>
						<FontAwesomeIcon
							icon={faChrome}
							style={{
								color: "orange",
								fontSize: "25px",
								margin: "0 15px",
							}}
						></FontAwesomeIcon>
						<span
							style={{
								margin: "auto",
								textAlign: "center",
								fontSize: "25px",
								color: "orange",
							}}
						>
							{currentUser.data.points + " pt"}
						</span>
						<span
							style={{ display: "block", margin: "15px", fontWeight: "500" }}
						>
							Referral:{" "}
							<span style={{ margin: "0 5px" }}>
								{currentUser.data.referralCode}
							</span>
						</span>
					</Paper>
					<Paper elevation={1}>
						<Form style={{ width: "80%", margin: "auto" }}>
							<Row className="mb-3" style={{ paddingTop: "15px" }}>
								{formFields.slice(0, 2).map(renderFormGroup)}
							</Row>
							<Row className="mb-3">
								{renderFormGroup(formFields[2])}
								<Form.Group as={Col} controlId="formGridPassword">
									<Form.Label>Password</Form.Label>
									<Form.Control
										type="password"
										placeholder="Password"
										disabled
									/>
								</Form.Group>
							</Row>
							{renderFormGroup(formFields[3])}
							<Row className="mb-3">
								{formFields.slice(4, 6).map(renderFormGroup)}
							</Row>
							<Row>{formFields.slice(6).map(renderFormGroup)}</Row>
							<Button
								className="m-5"
								variant="contained"
								type="submit"
								onClick={updateUser}
								style={{
									backgroundColor: "#094100",
									border: "none",
									width: "200px",
									margin: "auto",
									color: "white",
								}}
							>
								Submit
							</Button>
						</Form>
					</Paper>
				</Box>
			</ThemeProvider>
		</Grid>
	);
}
