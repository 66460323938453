import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
	return (
		<div className="footer">
			<p
				style={{
					flexBasis: "33.33%",
					fontSize: "10px",
					margin: "0",
					color: "white",
				}}
			>
				Copyright © 2024 H3BUDZ - All Rights Reserved.
			</p>
			<div style={{ flexBasis: "33.33%" }} className="socialIcon">
				<a href="https://www.facebook.com/h3social">
					<FontAwesomeIcon
						icon={faFacebookF}
						style={{ margin: "0 10px", fontSize: "15px" }}
					/>
				</a>
				<a href="https://www.instagram.com/h3social/">
					<FontAwesomeIcon
						icon={faInstagram}
						style={{ margin: "0 10px", color: "red", fontSize: "15px" }}
					/>
				</a>
			</div>
		</div>
	);
}
