import React, { useEffect, useState, useContext } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";

import { Chip, Paper, IconButton, Icon, Button, ButtonBase } from "@mui/material";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { url } from "../url";
import axios from "axios";
import { ProductContext } from "../ProductContext";
import AlertPopUp from "./AlertPopUp";
import AdditionalImagesUpload from "./Additional_Image_Upload";
import PopOver from "./PopOver.js";


export default function EditProduct(props) {
	const { show, hideEdit, productId, productData } = props;
	const { setDeleteConfirmation, deleteAdditionalImage, additionalImages, setAdditionalImages, getAdditionalImages, deleteConfirmation } = useContext(ProductContext);

	const [newImg, setNewImg] = useState("");
	const [newTitle, setNewTitle] = useState("");
	const [newPrice, setNewPrice] = useState(0);
	const [newSale, setNewSale] = useState(false);
	const [newChipData, setNewChipData] = useState([]);
	const [tag, setTag] = useState("");
	const [newDescription, setNewDescription] = useState("");
	const [imgName, setImgName] = useState("");
	const [newPointsNeeded, setNewPointsNeeded] = useState(0);
	const [count, setCount] = useState(0);
	const [newHotItem, setNewHotItem] = useState(0)
	const [newImgUrl, setNewImgUrl] = useState("")
	const [newCategory, setNewCategory] = useState("")

	const [newFeatureRedeem, setNewFeatureRedeem] = useState(0);
	const [file, setFile] = useState(null);

	const [open, setOpen] = useState(false);
	const [color, setColor] = useState("");
	const [message, setMessage] = useState("");
	const [showPopOver, setShowPopOver] = useState(false);


	const handleClick = (txt, color) => {
		setOpen(true);
		setMessage(txt);
		setColor(color);
	};

	const handleInputChange = (e) => {
		e.preventDefault();
		console.log(e.target.files);
		setImgName(e.target.files[0].name);
		setFile(e.target.files[0]);
		const file = e.target.files[0]
		if (file) {
			console.log(file)
			const reader = new FileReader();
			reader.onload = (e) => {
				setNewImgUrl(e.target.result);
			};
			reader.readAsDataURL(file);
		}
	};

	const addTag = () => {
		setNewChipData([
			...newChipData,
			{ key: tag + "t", label: tag, count: count },
		]);
		setTag("");
	};

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			addTag();
			e.target.value = "";
		}
	};

	const handleDelete = (chipToDelete) => () => {
		setNewChipData((chips) =>
			chips.filter((chip) => chip.label !== chipToDelete.label),
		);
	};

	const handleCountChange = (e, index) => {
		const updatedChipData = [...newChipData];
		updatedChipData[index].count = e.target.value;
		setNewChipData(updatedChipData);
	};

	const [imageName, setImageName] = useState("");

	const [selectedImages, setSelectedImages] = useState([]);
	// const [additionalImages, setAdditionalImages] = useState([])
	const [imgToDelete, setImgToDelete] = useState([])

	const confirmDelete = () => {
		setAdditionalImages(prevImages =>
			prevImages.filter(image => image.id !== imgToDelete.id)
		);
		deleteAdditionalImage(imgToDelete)
		if (deleteConfirmation) {
			handleClick("Product Deleted, redirect in 2 seconds !", "success");
		} else {
			handleClick("Something went wrong !", "error");
		}
		setDeleteConfirmation(false)
		setShowPopOver(false)
	};

	const handleDeleteImage = (el) => {
		setShowPopOver(true);
		setImgToDelete(el)
	};

	const submitEditProduct = async () => {
		console.log('===117' + newImgUrl)
		try {
			const formData = new FormData();
			formData.append("url", newImgUrl)
			formData.append("title", newTitle);
			formData.append("description", newDescription);
			formData.append("price", newPrice);
			formData.append("sale", newSale);
			// formData.append("url", newImg);
			formData.append("options", JSON.stringify(newChipData));
			formData.append("id", productId);
			formData.append("file", file);
			formData.append("pointsNeeded", newPointsNeeded);
			formData.append("featureRedeem", newFeatureRedeem);
			formData.append("hot", newHotItem)
			formData.append('category', newCategory)
			//==================additional images ===========================

			selectedImages.forEach((image) => {
				formData.append('selectedImages', image);
			});
			//-------------------end ---------------------------------------

			axios.put(url + "/product", formData).then(result => {
				if (result.data.message === 'update success') {
					handleClick("Product edited redirecting now !");
					setTimeout(() => {
						window.location.reload();
					}, 1000);
				}
			})

		} catch (error) {
			alert("error, contact admin");
		}
	};

	useEffect(() => {
		if (productData) {
			setNewImgUrl(productData.url)
			setNewDescription(productData.description);
			setNewTitle(productData.title);
			setNewPrice(productData.price);
			setNewSale(productData.sale);
			setNewChipData(productData.options);
			setNewPointsNeeded(productData.points_needed);
			setNewFeatureRedeem(productData.feature_redeem);
			setNewHotItem(productData.hot)
			setNewCategory(productData.category)
			productData.options?.map((el, index) => {
				el.length ? setCount(el?.options[index].count) : setCount(0);
			});
		}
	}, [productData]);

	useEffect(() => {
		if (show && productId) {
			getAdditionalImages(productId); // Fetch additional images when component is opened
		}
	}, [show, productId, getAdditionalImages]);

	return (
		<div>
			<AlertPopUp
				open={open}
				setOpen={setOpen}
				message={message}
				handleClick={handleClick}
				color={color}
			/>
			<PopOver
				open={showPopOver}
				setOpen={setShowPopOver}
				title="Confirm Delete ?"
				description="Once confirmed it will be deleted forever..."
				action={confirmDelete}
			/>
			<Modal show={show} onHide={hideEdit}>
				<Modal.Header closeButton>
					<Modal.Title>Update Product</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group>
							<img src={newImgUrl} style={{ maxWidth: '100%' }} />
							<Row className="mb-3">
								<IconButton
									color="primary"
									aria-label="upload picture"
									component="label"
									sx={{ width: "100px" }}
								>
									<input
										hidden
										accept="image/*"
										type="file"
										onChange={handleInputChange}
									/>
									<PhotoCamera sx={{ fontSize: "50px" }} />
								</IconButton>
							</Row>
						</Form.Group>

						<Form.Group>
							<Form.Label>Upload Additional Images</Form.Label>
							<span>
								<AdditionalImagesUpload selectedImages={selectedImages} setSelectedImages={setSelectedImages} additionalImages={additionalImages} />
							</span>
						</Form.Group>
						{
							<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
								{
									additionalImages?.map((el, index) => {
										return <div key={index} style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-around' }}>
											<img src={el.url} key={index} style={{
												objectFit: 'contain',
												width: '100px',
												margin: '15px'
											}} />
											<Button color="error" onClick={() => handleDeleteImage(el)}>Delete</Button>
										</div>
									})
								}
							</Form.Group>
						}
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label>Category {productId}</Form.Label>
							<Form.Control
								type="text"
								placeholder="title"
								autoFocus
								value={newCategory}
								onChange={(e) => {
									setNewCategory(e.target.value);
								}}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label>Title {productId}</Form.Label>
							<Form.Control
								type="text"
								placeholder="title"
								autoFocus
								value={newTitle}
								onChange={(e) => {
									setNewTitle(e.target.value);
								}}
							/>
						</Form.Group>
						<Form.Group
							className="mb-3"
							controlId="exampleForm.ControlTextarea1"
						>
							<Form.Label>Description</Form.Label>
							<Form.Control
								as="textarea"
								value={newDescription}
								onChange={(e) => {
									setNewDescription(e.target.value);
								}}
							/>
						</Form.Group>
						<Form.Group
							className="mb-3"
							controlId="exampleForm.ControlTextarea1"
						>
							<Form.Label>Price</Form.Label>
							<Form.Control
								type="Number"
								value={newPrice}
								onChange={(e) => {
									setNewPrice(e.target.value);
								}}
							/>
						</Form.Group>
						<Form.Group className="mb-3" as={Col} controlId="formGridSale">
							<Form.Label className="">Sale?</Form.Label>
							<Form.Select
								aria-label="Default select example"
								value={newSale}
								onChange={(e) => setNewSale(e.target.value)}
							>
								<option value="0">False</option>
								<option value="1">True</option>
							</Form.Select>
						</Form.Group>
						<Form.Group className="mb-3" as={Col} controlId="formGridSale">
							<Form.Label className="">Featured Redeem?</Form.Label>
							<Form.Select
								disabled
								aria-label="Default select example"
								value={newFeatureRedeem}
								onChange={(e) => setNewFeatureRedeem(e.target.value)}
							>
								<option value="0">False</option>
								<option value="1">True</option>
							</Form.Select>
						</Form.Group>
						<Form.Group className="mb-3" as={Col} controlId="formGridSale">
							<Form.Label className="">Hot Item?</Form.Label>
							<Form.Select
								aria-label="Default select example"
								value={newHotItem}
								onChange={(e) => setNewHotItem(e.target.value)}
							>
								<option value="0">False</option>
								<option value="1">True</option>
							</Form.Select>
						</Form.Group>

						<Form.Group className="mb-3" as={Col} controlId="formGridStoreOne">
							<Form.Label className="">Points needed to redeem</Form.Label>
							<Form.Control
								type="text"
								placeholder="Points needed"
								value={newPointsNeeded}
								onChange={(e) => setNewPointsNeeded(e.target.value)}
							/>
						</Form.Group>

						<Form.Group
							as={Col}
							controlId="formGridCategory"
							className="col-8-md col-12-sm mb-3"
							style={{ margin: "auto" }}
						>
							<Form.Label className="">Item Options</Form.Label>
							<div className="d-flex">
								<Form.Control
									style={{ marginRight: "25px", width: "100%" }}
									type="text"
									placeholder="Item Options"
									onChange={(e) => setTag(e.target.value)}
									onKeyDown={handleKeyDown}
								/>
								<Button onClick={addTag} variant="contained" color="success">
									+
								</Button>
							</div>
						</Form.Group>
						<Paper
							sx={{
								display: "flex",
								justifyContent: "center",
								flexWrap: "wrap",
								listStyle: "none",
								p: 3,
								m: 0,
							}}
							component="ul"
						>
							{newChipData?.map((data, index) => {
								let icon;
								return (
									<div key={index}>
										<Chip
											icon={icon}
											label={data.label}
											onDelete={handleDelete(data)}
											sx={{ background: "", color: "", m: 1 }}
										/>
									</div>
								);
							})}
						</Paper>
						<Form.Group>
							{newChipData?.map((data, index) => {
								return (
									<div
										style={{
											display: "flex",
											justifyContent: "space-between",
											marginTop: "15px",
										}}
										key={index}
									>
										<Form.Label className="">
											{data.label.toUpperCase()}
										</Form.Label>

										<input
											type="number"
											value={data.count}
											onChange={(e) => handleCountChange(e, index)}
											style={{ width: "80px" }}
										/>
									</div>
								);
							})}
						</Form.Group>
						{/*
							<Row className="mb-3">
								<IconButton
									color="primary"
									aria-label="upload picture"
									component="label"
									sx={{ width: "100px" }}
								>
									<input
										hidden
										accept="image/*"
										type="file"
										onChange={handleInputChange}
									/>
									<PhotoCamera sx={{ fontSize: "50px" }} />
								</IconButton>
								<span id="imageName">{imgName}</span>
							</Row>
						*/}
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={hideEdit}>
						Close
					</Button>
					<Button variant="primary" onClick={submitEditProduct}>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
