import React, { useContext, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import ConfirmationPopup from "./ConfirmationPopup";
import { MessageContext } from "../MessageContext";

export default function ContactByOrder(props) {
	const { sendMessage } = useContext(MessageContext);

	const { show, hideMessage, orderNumber, email, name, userId } = props;
	const [openConFirmationPopup, setOpenConfirmationPopup] = useState(false);

	const [message, setMessage] = useState("");

	return (
		<div>
			<ConfirmationPopup
				openConFirmationPopup={openConFirmationPopup}
				setOpenConfirmationPopup={setOpenConfirmationPopup}
			/>
			<Modal show={show} onHide={hideMessage}>
				<Modal.Header closeButton>
					<Modal.Title>Question for #{orderNumber.toUpperCase()}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label style={{ float: "left" }}>Message</Form.Label>
							<Form.Control
								as="textarea"
								rows={5}
								name="message"
								placeholder="message"
								value={message}
								onChange={(e) => {
									setMessage(e.target.value);
								}}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={hideMessage}>
						Close
					</Button>
					<Button
						variant="primary"
						onClick={() => sendMessage(email, message, orderNumber, name)}
					>
						Send
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
