import React, { useState, useContext, useEffect } from "react";
import { Form, Button, FormLabel, FormControl } from "react-bootstrap";
import axios from "axios";
import { url } from "../url";
import ConfirmationPopup from "./ConfirmationPopup";
import { useNavigate, useLocation } from "react-router-dom";
import { LanguageContext } from "../controller/LanguageContext";

export default function Email(props) {
	const navigate = useNavigate();
	const location = useLocation();
	const dataReceived = location.state;

	const [emails, setEmails] = useState(location.state || []);
	const [message, setMessage] = useState("");
	const [title, setTitle] = useState("");
	const [link, setLink] = useState("");
	const [openConFirmationPopup, setOpenConfirmationPopup] = useState(false);

	const handleRemove = (removeIndex) => {
		// Filter out the email object that has the matching index
		const updatedEmails = emails.selectedEmails?.filter(
			(el) => el.index !== removeIndex
		);
		setEmails({ ...emails, selectedEmails: updatedEmails });
	};

	const sendEmail = () => {
		axios
			.post(url + "/send_email", { emails, message, title, link })
			.then((response) => {
				console.log(response);
				setOpenConfirmationPopup(true);
				setTimeout(() => {
					navigate("/shop");
				}, 2100);
			});
	};

	return (
		<div
			className="fadeIn contact-container"
			style={{ paddingBottom: "100px" }}
		>
			<ConfirmationPopup
				openConFirmationPopup={openConFirmationPopup}
				setOpenConfirmationPopup={setOpenConfirmationPopup}
			/>
			<div className="contact-section">
				<Form style={{ width: "100%" }}>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						{emails?.selectedEmails?.map((el, index) => {
							return (
								<div style={{ textAlign: "right" }} key={index}>
									<span>{el.email}</span>
									<span style={{ marginLeft: "15px" }}>
										<button
											type="button"
											style={{
												background: "red",
												color: "white",
												// borderRadius: "",
												border: "none ",
												margin: "10px",
											}}
											onClick={() => handleRemove(el.index)}
										>
											X
										</button>
									</span>
								</div>
							);
						})}
					</Form.Group>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label style={{ float: "left" }}>Title</Form.Label>
						<Form.Control
							type="text"
							name="name"
							placeholder="name"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							required
							style={{
								background: "black",
								color: "white",
								borderRadius: "0",
							}}
						/>
					</Form.Group>

					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label style={{ float: "left" }}>Message</Form.Label>
						<Form.Control
							as="textarea"
							rows={5}
							name="message"
							placeholder="message"
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							style={{
								background: "black",
								color: "white",
								borderRadius: "0",
							}}
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label style={{ float: "left" }}>Link(optional)</Form.Label>
						<Form.Control
							type="text"
							name="name"
							placeholder=" follow format like => google.com"
							value={link}
							onChange={(e) => setLink(e.target.value)}
							required
							style={{
								background: "black",
								color: "white",
								borderRadius: "0",
							}}
						/>
					</Form.Group>
				</Form>
			</div>

			<div style={{ margin: "10px" }}>
				<Button onClick={sendEmail}>send</Button>
				{/* <Button
					style={{
						backgroundColor:
							emailData.name.length === 0 ||
							emailData.email.length === 0 ||
							emailData.message.length === 0
								? "gray"
								: "#094100",
						border: "none",
						width: "200px",
						margin: "auto",
						borderRadius: "0",
					}}
					className="btn btn-primary buttons"
					onClick={sendMessage}
					disabled={
						emailData.name.length === 0 ||
						emailData.email.length === 0 ||
						emailData.message.length === 0
					}
				>
					{emailData.name.length === 0 ||
					emailData.email.length === 0 ||
					emailData.message.length === 0 ? (
						<>Data Required</>
					) : (
						<>Submit</>
					)}
				</Button> */}
			</div>
		</div>
	);
}
