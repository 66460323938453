import { React, useState, useContext } from "react";
import { Paper, Box, Button, Modal, Typography } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useNavigate } from "react-router-dom";
import AlertPopUp from "./AlertPopUp";
import { DashContext } from "../DashContext";
import { Form } from "react-bootstrap";

export default function UsePointsTable(props) {
	const { data, userGroups } = props;
	const navigate = useNavigate();
	const {
		addUsersToGroup,
		selectedEmails,
		setSelectedEmails,
		setSelectGroup,
		open,
		color,
		message,
		handleClick,
		setOpen,
		showAvailableGroup,
		setShowAvailableGroup,
		hideAvailableGroup,
	} = useContext(DashContext);

	// Function to handle selecting or deselecting all emails
	const handleSelectAll = (event) => {
		if (event.target.checked) {
			// Selecting all emails
			const allEmails = data.map((el, index) => ({
				id: el.id,
				index: index,
				email: el.email,
				name: `${el.firstName} ${el.lastName}`,
			}));
			setSelectedEmails(allEmails);
		} else {
			// Deselect all
			setSelectedEmails([]);
		}
	};

	// Function to handle selecting individual emails
	const handleSelectEmail = (emailObj) => {
		const isSelected = selectedEmails.some(
			(selected) => selected.email === emailObj.email
		);

		if (isSelected) {
			// Remove email if already selected
			setSelectedEmails(
				selectedEmails.filter((selected) => selected.email !== emailObj.email)
			);
		} else {
			// Add the email object to the selectedEmails array
			setSelectedEmails([...selectedEmails, emailObj]);
		}
	};

	// Function to copy selected emails to clipboard
	const copyEmailsToClipboard = () => {
		// Extract emails from selectedEmails array
		const emailString = selectedEmails
			.map((emailObj) => emailObj.email)
			.join(", ");

		// Use clipboard API to copy the string
		navigator.clipboard
			.writeText(emailString)
			.then(() => {
				handleClick(
					`${selectedEmails.length} Emails copied to clipboard!`,
					"success"
				);
			})
			.catch((err) => {
				handleClick("Copy failed!", "error");
			});
	};

	// Function to navigate and pass selected emails
	const email = () => {
		const dataToSend = selectedEmails;
		// Navigate to the target route and pass the data as state
		navigate("/email", { state: { selectedEmails: dataToSend } });
	};

	// Check if all emails are selected
	const isAllSelected =
		data?.length > 0 && selectedEmails?.length === data.length;

	const [sortedData, setSortedData] = useState(data);
	const [sortOrder, setSortOrder] = useState("asc");

	const sortToggle = (item) => {
		return function (array) {
			// Sort function using Intl.Collator for flexible sorting
			const collator = new Intl.Collator(undefined, {
				numeric: true,
				sensitivity: "base",
			});
			setSortedData(
				array.sort(
					(a, b) =>
						(sortOrder === "asc" ? 1 : -1) * collator.compare(a[item], b[item])
				)
			);

			// Toggle the sort order for the next click
			setSortOrder(sortOrder === "asc" ? "desc" : "asc");
		};
	};

	const sortFirstName = sortToggle("firstName");
	const sortLastName = sortToggle("lastName");
	const sortAddress = sortToggle("address");
	const sortCity = sortToggle("city");
	const sortEmail = sortToggle("email");
	const sortPoints = sortToggle("points");
	const sortGroup = sortToggle("user_group_name");

	return (
		<TableContainer
			component={Paper}
			sx={{ marginBottom: "50px", paddingBottom: "50px;" }}
		>
			<AlertPopUp
				open={open}
				setOpen={setOpen}
				message={message}
				handleClick={handleClick}
				color={color}
			/>
			<Box
				sx={{
					margin: "15px 20px",
					padding: "15px",
				}}
			>
				<Box
					sx={{ mt: 3, margin: "auto", display: "block", fontWeight: "bold" }}
				>
					<Box>
						<Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
							<span style={{ color: "orange" }}>{data?.length}</span> USER FOUND
						</Typography>
					</Box>
				</Box>
				<Box
					sx={{
						mt: 3,
						display: { xs: "block", md: "flex" },
						justifyContent: "space-between",
					}}
				>
					<Box>
						<Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
							<span style={{ color: "orange" }}>{selectedEmails.length} </span>
							{`EMAILS SELECTED `}
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
						}}
					>
						<Button
							variant="contained"
							onClick={email}
							disabled={!selectedEmails.length}
							sx={{
								width: "100px",
								height: "36.5px",
								m: 1,
							}}
						>
							SEND
						</Button>
						<Button
							variant="contained"
							sx={{
								m: 1,
								width: "100px",
								height: "36.5px",
							}}
							onClick={copyEmailsToClipboard}
							disabled={selectedEmails.length === 0}
						>
							COPY
						</Button>
						<Button
							variant="contained"
							sx={{
								m: 1,
								width: "100px",
								height: "36.5px",
							}}
							onClick={() => setShowAvailableGroup(true)}
							disabled={selectedEmails.length === 0}
						>
							GROUP
						</Button>
					</Box>
				</Box>
			</Box>
			<Table sx={{ minWidth: 1080 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell padding="checkbox" align="center">
							<Checkbox
								checked={isAllSelected}
								onChange={handleSelectAll}
								indeterminate={
									selectedEmails.length > 0 &&
									selectedEmails.length < data.length
								}
								inputProps={{ "aria-label": "select all emails" }}
							/>
						</TableCell>
						<TableCell
							align="center"
							onClick={() => sortGroup(data)}
							sx={{
								"&:hover": {
									cursor: "pointer", // Show cursor on hover
									color: "primary.main", // Optional: Change color on hover
								},
								p: 1,
							}}
						>
							{sortOrder === "asc" ? <ExpandLessIcon /> : <ExpandMoreIcon />}
							Group
						</TableCell>
						<TableCell
							align="center"
							onClick={() => sortFirstName(data)}
							sx={{
								"&:hover": {
									cursor: "pointer", // Show cursor on hover
									color: "primary.main", // Optional: Change color on hover
								},
								p: 1,
							}}
						>
							First Name
						</TableCell>
						<TableCell
							align="center"
							onClick={() => sortLastName(data)}
							sx={{
								"&:hover": {
									cursor: "pointer", // Show cursor on hover
									color: "primary.main", // Optional: Change color on hover
								},
								p: 1,
							}}
						>
							Last Name
						</TableCell>
						<TableCell
							align="center"
							onClick={() => sortAddress(data)}
							sx={{
								"&:hover": {
									cursor: "pointer", // Show cursor on hover
									color: "primary.main", // Optional: Change color on hover
								},
								p: 1,
							}}
						>
							Address
						</TableCell>
						<TableCell
							align="center"
							onClick={() => sortCity(data)}
							sx={{
								"&:hover": {
									cursor: "pointer", // Show cursor on hover
									color: "primary.main", // Optional: Change color on hover
								},
								p: 1,
							}}
						>
							City
						</TableCell>
						<TableCell align="center">Phone</TableCell>
						<TableCell
							align="center"
							onClick={() => sortEmail(data)}
							sx={{
								"&:hover": {
									cursor: "pointer", // Show cursor on hover
									color: "primary.main", // Optional: Change color on hover
								},
								p: 1,
							}}
						>
							Email
						</TableCell>
						<TableCell
							align="center"
							onClick={() => sortPoints(data)}
							sx={{
								"&:hover": {
									cursor: "pointer", // Show cursor on hover
									color: "primary.main", // Optional: Change color on hover
								},
								p: 1,
							}}
						>
							Points
						</TableCell>
						<TableCell align="center">Date</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data?.map((el, index) => (
						<TableRow
							key={index}
							sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
						>
							<TableCell padding="checkbox" align="center">
								<Checkbox
									checked={selectedEmails.some(
										(selected) => selected.email === el.email
									)}
									onChange={() =>
										handleSelectEmail({
											index,
											id: el.id,
											email: el.email,
											name: `${el.firstName} `,
										})
									}
									inputProps={{ "aria-label": `select email ${el.email}` }}
								/>
							</TableCell>
							<TableCell align="center">
								{el.user_group_name ? el.user_group_name.toUpperCase() : ""}
							</TableCell>

							<TableCell align="center">{el.firstName.toUpperCase()}</TableCell>
							<TableCell align="center">{el.lastName.toUpperCase()}</TableCell>
							<TableCell align="left">{el?.address?.toUpperCase()}</TableCell>
							<TableCell align="center">{el.city?.toUpperCase()}</TableCell>

							<TableCell align="center">{el.phone}</TableCell>
							<TableCell align="center">{el.email?.toUpperCase()}</TableCell>
							<TableCell align="center">{el.points}</TableCell>
							<TableCell align="center">
								{new Date(el.updated).toISOString().split("T")[0]}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<Modal
				open={showAvailableGroup}
				onClose={hideAvailableGroup}
				sx={{
					margin: "auto",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Paper>
					<Form className="mb-3" style={{ margin: "15px", width: "350px" }}>
						<Form.Group>
							<Form.Label>Available Group</Form.Label>
							<Form.Select
								aria-label="Default select example"
								defaultValue="option"
								onChange={(e) => setSelectGroup(e.target.value)}
							>
								<option defaultValue="select a product">--</option>
								{userGroups?.map((el, index) => {
									return (
										<option value={el.id} key={index}>
											{el.group_name + " " + el.id}
										</option>
									);
								})}
							</Form.Select>
						</Form.Group>
						<Form.Group className="mt-3">
							<Button variant="contained" onClick={addUsersToGroup}>
								Save
							</Button>
						</Form.Group>
					</Form>
				</Paper>
			</Modal>
		</TableContainer>
	);
}
